import { WORDS_DIFFICULT } from '../constants/wordlist'
import { WORDS_MEDIUM } from '../constants/wordlist_medium'
import { WORDS_EASY } from '../constants/wordlist_easy'
import { WORDS_CITIES } from '../constants/wordlist_cities'

import { VALID_GUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  return (
    VALID_GUESSES.includes(word.toLowerCase())
  )
}

// export const isWinningWord = (word: string) => {
//   return solution === word
// }

export const getWordOfDay = (difficulty: string) => {

  if (difficulty === "legendary") {
    //console.log(`legendary: ${difficulty}`)
    const index = Math.floor(Math.random() * (5000 - 1 + 1)) + 1;
    //console.log(WORDS_DIFFICULT[index % WORDS_DIFFICULT.length].toUpperCase())

  //const index = 0;
    return {
      solution: WORDS_DIFFICULT[index % WORDS_DIFFICULT.length].toUpperCase(),
    
    }
  }
  if (difficulty === "world-class") {
    //console.log(`world-class: ${difficulty}`)

    const index = Math.floor(Math.random() * (5000 - 1 + 1)) + 1;
    //console.log(WORDS_MEDIUM[index % WORDS_MEDIUM.length].toUpperCase())

  //const index = 0;
    return {
      solution: WORDS_MEDIUM[index % WORDS_MEDIUM.length].toUpperCase(),
    
    }
  }

  if (difficulty === "cities") {
    //console.log(`world-class: ${difficulty}`)

    const index = Math.floor(Math.random() * (237 - 1 + 1)) + 1;
    //console.log(WORDS_MEDIUM[index % WORDS_MEDIUM.length].toUpperCase())

  //const index = 0;
    return {
      solution: WORDS_CITIES[index % WORDS_CITIES.length].toUpperCase(),
    
    }
  }


  //console.log(`kids: ${difficulty}`)

    // else: difficulty = "kids"  
    const index = Math.floor(Math.random() * (5000 - 1 + 1)) + 1;
    //console.log(WORDS_EASY[index % WORDS_EASY.length].toUpperCase())
  //const index = 0;
    return {
      solution: WORDS_EASY[index % WORDS_EASY.length].toUpperCase(),
    
    }
  
}

export const { solution } = getWordOfDay("kids")
