export const GAME_TITLE = 'وردل فارسی'

export const WIN_MESSAGES = ['استثنایی !!!','نابغه','عالی','آفرین','باریکلا','بالاخره شد!!!']
export const GAME_COPIED_MESSAGE = 'بازی کپی شد'
export const ABOUT_GAME_MESSAGE = 'درباره این بازی'
export const OPEN_KEYBOARD = 'صفحه کلید موبایل'

export const NOT_ENOUGH_LETTERS_MESSAGE = 'تعداد کلمات کافی نیست'
export const WORD_NOT_FOUND_MESSAGE = 'این واژه در دیکشنری ما وجود ندارد'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  ` واژه مورد نظر ${solution} بود`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'آمار'
export const GUESS_DISTRIBUTION_TEXT = 'توزیع حدس ها'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'اشتراک گذاری'
export const TOTAL_TRIES_TEXT = 'تعداد حدس ها'
export const SUCCESS_RATE_TEXT = 'نرخ موفقیت'
export const CURRENT_STREAK_TEXT = 'تعداد بردهای پیاپی کنونی'
export const BEST_STREAK_TEXT = 'بهترین بردهای پیاپی'

export const LEVEL_LEGENDARY = 'legendary'
export const LEVEL_WORLD_CLASS = 'world-class'
export const LEVEL_KIDS = 'kids'
export const LEVEL_CITIES = 'cities'