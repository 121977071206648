import { ExclamationCircleIcon, ChartPieIcon } from '@heroicons/react/outline'
import { useState, useEffect, SetStateAction } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { WordModal } from './components/modals/WordModal'
import { UCubeModal } from './components/modals/UniversityCubeModal'

import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  OPEN_KEYBOARD,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  LEVEL_KIDS,
  LEVEL_LEGENDARY,
  LEVEL_WORLD_CLASS,
  LEVEL_CITIES,
} from './constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  ALERT_TIME_MS,
  REVEAL_TIME_MS,
} from './constants/settings'
import { isWordInWordList, getWordOfDay } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'

import './App.css'

function App() {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const printWord = (word: string) => {
    return
    //const token = '69628.nmu4eVqUg2BYF6adMOrKpzwI5crn6iYEM7h7cV03'
    // fetch(
    //   `https://api.vajehyab.com/v3/word?token=${token}&title=${word}&db=amid&num=1`
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     //console.log(data.word.text)
    //     if (data?.word?.text !== '') {
    //       document.getElementById('meaning')!.innerHTML = data.word.text
    //       return data.word.text
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error)
    //   })
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const clearState = () => {
    document
      .getElementById('correct-word-alert')
      ?.style.setProperty('width', '0')
    setIsGameLost(false)
    setCurrentGuess('')
    setIsGameWon(false)
    setIsInfoModalOpen(false)
    // setIsUniversityCubeModalOpen(false)
    setIsAboutModalOpen(false)
    setIsNotEnoughLetters(false)
    setIsStatsModalOpen(false)
    setIsWordModalOpen(false)
    setIsWordNotFoundAlertOpen(false)
    setIsDarkMode(true)
    setSuccessAlert('')
    setIsRevealing(false)
    //setShowSolution(false)
    setGuesses([])
    setStats(loadStats())
    setResetDisabled(true)
    //setDifficulty(LEVEL_WORLD_CLASS);
    //const prevSolution = solution
    //printWord(prevSolution)
    setSolution(getWordOfDay(difficulty).solution)
  }

  const handleChange = (event: {
    target: { value: SetStateAction<string> }
  }) => {
    //console.log("Level Changed!!");
    setDifficulty(event.target.value)
  }
  const options = [
    {
      label: 'نام شهر',
      value: LEVEL_CITIES,
      tooltip: 'حدس شهر از میان 250 شهر ایران',
    },
    {
      label: 'افسانه ای',
      value: LEVEL_LEGENDARY,
      tooltip: 'حدس واژه درست در میان 4000 واژه فارسی',
    },
    {
      label: 'کلاس جهانی',
      value: LEVEL_WORLD_CLASS,
      tooltip: 'حدس واژه درست در میان 1000 واژه فارسی',
    },
    {
      label: 'آسان :)',
      value: LEVEL_KIDS,
      tooltip: 'حدس واژه درست در میان 300 واژه فارسی',
    },
  ]

  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const [currentGuess, setCurrentGuess] = useState('')
  //const [showSolution, setShowSolution] = useState(false)
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordModalOpen, setIsWordModalOpen] = useState(false)
  const [isUniversityCubeModalOpen, setIsUniversityCubeModalOpen] = useState(
    true
  )

  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [difficulty, setDifficulty] = useState(LEVEL_KIDS)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? true /*localStorage.getItem('theme') === 'dark'*/
      : prefersDarkMode
        ? true
        : true
  )
  const [solution, setSolution] = useState<string>(() => {
    let { solution } = getWordOfDay(difficulty)
    return solution
  })
  const [successAlert, setSuccessAlert] = useState('')
  const [resetDisabled, setResetDisabled] = useState(false)
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded?.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(false)
    }
    if (loaded?.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(false)
    }
    //return loaded.guesses
    return []
  })

  const [stats, setStats] = useState(() => loadStats())

  const isWinningWord = (word: string) => {
    return solution === word
  }

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  // const handleDarkMode = (isDark: boolean) => {
  //   //setIsDarkMode(isDark)
  //   setIsDarkMode(true)
  //   localStorage.setItem('theme', isDark ? 'dark' : 'light')
  // }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses, solution])
  useEffect(() => {
    document
      .getElementById('correct-word-alert')
      ?.style.setProperty('width', '0')
    setIsGameLost(false)
    setCurrentGuess('')
    setIsGameWon(false)
    //setIsInfoModalOpen(true);
    setIsAboutModalOpen(false)
    setIsNotEnoughLetters(false)
    setIsStatsModalOpen(false)
    setIsWordModalOpen(false)
    // setIsUniversityCubeModalOpen(false)
    setIsWordNotFoundAlertOpen(false)
    setIsDarkMode(true)
    setSuccessAlert('')
    setIsRevealing(false)
    setGuesses([])
    setStats(loadStats())
    setResetDisabled(true)
    //setShowSolution(false)
    //setDifficulty(LEVEL_WORLD_CLASS);
    setSolution(getWordOfDay(difficulty).solution)
  }, [difficulty])

  useEffect(() => {
    if (isGameWon) {
      // let res = printWord(solution)
      // console.log(res)
      setIsWordModalOpen(true)
      printWord(solution)
      setTimeout(() => {
        setSuccessAlert(WIN_MESSAGES[guesses.length - 1])

        setTimeout(() => {
          setSuccessAlert('')
        }, ALERT_TIME_MS)
      }, REVEAL_TIME_MS * MAX_WORD_LENGTH)
    }
    if (isGameLost) {
      // let res = printWord(solution)
      // console.log(res)
      setIsWordModalOpen(true)
      printWord(solution)
      setTimeout(() => {
        //setShowSolution(true)
        setTimeout(() => {
          //setShowSolution(false)
        }, REVEAL_TIME_MS * MAX_WORD_LENGTH)
      }, REVEAL_TIME_MS * MAX_WORD_LENGTH)
    }
  }, [isGameWon, isGameLost, guesses.length, solution])

  const onChar = (value: string) => {
    //document.getElementById("reset-game")?.setAttribute("disabled", "false");

    if (
      currentGuess.length < MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    //document.getElementById("reset-game")?.setAttribute("disabled", "false");
    setResetDisabled(false)

    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === MAX_WORD_LENGTH)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    const winningWord = isWinningWord(currentGuess)

    if (
      currentGuess.length === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
      }
    }
  }

  return (
    <div className=" pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      {/* <div className=' max-w-[500px] absolute hidden 2xl:block left-[10px] top-[10px]
            bg-white rounded-none md:rounded-lg
            flex flex-col items-center justify-center p-6 py-10 mx-auto'>
            <h1 className='leading-loose tracking-wide my-2 text-lg lg:text-3xl font-extrabold text-gray-800'>What is Cod<span className='text-4xl font-extrabold text-purple-400'>y</span>ng?</h1>
            <p className='rounded-none min-w-full p-4 leading-loose tracking-wide my-2 text-md lg:text-xl  font-extrabold text-gray-800'>Codyng is just like coding <span className='text-lg text-identity'>except</span> there is no 
            <span className='text-red-400 text-italic'> I </span> in Codyng. That's because you'll never code alone here!</p>
            <p className='min-w-full p-4 leading-loose tracking-wide my-4 text-md lg:text-xl  font-extrabold text-gray-800'>But seriously, we offer the typical leetcode style workspace for you with the addition of an AI helper that provides you with reliable timely feedback on your code and how to solve the problem you're working on.</p>
 <a href='https://codyng.io/#section2' target="_blank"    rel="noreferrer"             
 className={`bg-[#f5f5f5] text-gray-800 
 hover:scale-[1.02] text-lg 
 focus:scale-[1.02] active:scale-100 mb-6
 p-6 rounded-lg w-56 text-center font-bold shadow-purple-400 shadow-md hover:shadow-xl hover:shadow-purple-600`}
              > 
                Try it yourself</a>

          </div> */}
      <div className="flex items-stretch w-80 mx-auto mb-8 mt-4">
        <button
          id="reset-game"
          disabled={resetDisabled}
          onClick={clearState}
          className=" text-sm w-1/3 h-fit mx-4 bg-green-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full"
        >
          واژه بعدی
        </button>
        <div style={{ border: '2px solid white' }} className="w-1/3 mx-4 h-fit">
          <select
            className="w-full text-sm border-slate-400 text-gray-500 dark:text-white dark:bg-slate-900 cursor-pointer"
            title="سطح بازی"
            value={difficulty}
            onChange={handleChange}
          >
            {options.map((option) => (
              <option
                className="cursor-pointer"
                key={option.value}
                title={option.tooltip}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <ExclamationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartPieIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
        {/* <ExclamationCircleIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsWordModalOpen(true)}
        /> */}
      </div>
      <Grid
        solution={solution}
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
      />
      <Keyboard
        solution={solution}
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />

      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <UCubeModal

        isOpen={isUniversityCubeModalOpen}
        lastWord={""}
        handleClose={() => setIsUniversityCubeModalOpen(false)}
      />
      <WordModal
        isOpen={isWordModalOpen}
        lastWord={solution}
        handleClose={() => setIsWordModalOpen(false)}
      />

      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      {/* <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        {ABOUT_GAME_MESSAGE}
      </button> */}
      {width <= 1200 ? (
        <div className="flex flex-col max-auto">
          <button
            type="button"
            className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={() => document.getElementById('text1')?.focus()}
          >
            {OPEN_KEYBOARD}
          </button>
        </div>
      ) : (
        <div className="flex flex-col max-auto mt-6"></div>
      )}

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert
        message={CORRECT_WORD_MESSAGE(solution)}
        isOpen={false /*showSolution === true*/}
      />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
      <div className="phantom"></div>
      <div className="footer">
        {' '}
        <p>
          &copy; 2022{' '}
          <a
            style={{ fontSize: '16px', fontWeight: 'bold', color: 'orangered' }}
            target={'blank'}
            href="https://ashkanbashiri.github.io"
          >
            Ashkan Bashiri
          </a>{' '}
        </p>
      </div>
    </div>
  )
}

export default App
