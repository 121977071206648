export const WORDS_EASY = [
    'ابادی',
  'یحتمل',

  'انجیر',
  'انجین',
  'انحنا',
  'اندام',
  'اندرز',
  'اندکی',
  'اندوه',
  'انذار',
  'انرژی',
  'انزوا',
  'انسان',
  'انصاف',
  'انعام',
  'انعام',
  'انفیه',
  'انقضا',
  'انکار',
  'انگار',
  'انگشت',
  'انگور',
  'انواع',
  'بدقلق',
  'بدقول',
  'بدکار',
  'بدکنش',
  'بدکیش',
  'بدکین',
  'بدگلی',
  'بدلقا',
  'بدمزه',
  'بدمست',
  'بدمنش',
  'بدمهر',
  'بدنام',
  'بدنسل',
  'بدنما',
  'بدنیت',
  'بدویت',
  'بدهضم',
  'بدیمن',
  'بدیهی',
  'برایت',
  'برابر',
  'برادر',
  'برازخ',
  'براعت',
  'برایا',
  'برایی',
  'برتری',
  'برجیس',
  'برچسب',
  'برحسب',
  'برخور',
  'بردار',
  'بردار',
  'بردگی',
  'بررسی',
  'برزگر',
  'برشتن',
  'برشته',
  'برطبق',
  'برطرف',
  'برعکس',
  'برکشی',
  'برگچه',
  'برگشت',
  'برملا',
  'برمنش',
  'برنده',
  
'بلاهت',
'بلایا',
'بلبشو',
'بلدان',
'بلدیه',
'بلندا',
'بلندی',
'بلوار',
'بلهوس',
'بمورد',
'بموقع',
'بنادر',
'بنچاق',
'بندشی',
'بندگی',

'تخییل',
'تدارک',
'تداعی',
'تدافع',
'تداول',
'تداوم',
'تداوی',
'تدبیر',
'تدخین',
'تدفین',
'تدقیق',
'تدلیس',
'تدمیر',
'تدویر',
'تدوین',

'جادار',
'جاذبه',
'جارچی',
'جاسوس',
'جاکشی',
'جالیز',
'جامعه',
'جانان',
'جانور',
'جاوید',
'جبران',
'جبروت',
'جدایی',
'جراحت',
'جراید',
'جربزه',
'جریان',
'جریحه',
'جریده',
'جریمه',
'جزیره',
'جسارت',
'جسامت',
'جستار',
'جستجو',
'جعودت',
'جفاجو',
'جلالت',
'جلباب',
'جمعیت',
'جملگی',
'جمهور',
'جنازه',
'جنایت',
'جنجال',
'جنگجو',
'جندگی',
'جنگجو',
'جوارح',
'جوانب',
'جوانی',
'جواهر',
'جولان',
'جولاه',
'جوهره',
'جویدن',
'جهانی',
'جهودی',
'جهیدن',
'جیحون',
'جیران',
'چابکی',
'چاپار',
'چاپچی',
'چاپگر',
'چاچول',
'چاخان',
'چادری',
'چارپا',
'چارتر',
'چارسو',
'چارقد',
'چاروا',
'چاشنی',
'چاکری',
'چالاک',
'چامین',
'چاووش',
'چپانی',
'چپاول',
'چپوچی',
'چخماق',
'چخیدن',
'چراگه',
'چرایی',
'چرتکه',
'چرخاب',
'چرخان',
'چرخشی',
'چرکین',
'چرمین',
'چرنده',
'چریدن',
'چریکی',
'چشایی',
'چشیدن',
'چغاله',
'چغانه',
'چکامه',
'چکاوک',
'چکیدن',
'چکیده',
'چگالی',
'چگونه',
'چلتوک',
'چلچله',
'چلچلی',
'چلنگر',
'چلیپا',
'چلیدن',
'چمانه',
'چمانی',
'چمدان',
'چمیدن',
'چنبره',
'چندان',
'چندین',
'چنگار',
'چنگال',
'چنگول',
'چوبین',
'چوپان',
'چوگان',
'چهچهه',
'چیرگی',
'چیستی',
'چیلان',
'حاجات',
'حادثه',
'حاشاک',
'حاشیه',
'حاصله',
'حاضری',
'حافظه',
'حالات',
'حالیا',
'حالیه',
'حامله',
'حباله',
'حبسیه',
'حتمیت',
'حجابت',
'حجاری',
'حجامت',
'حجرات',
'حداثت',
'حدادی',
'حداقل',
'حدایق',
'حدساً ',
'حدیده',
'حدیقه',
'حراثت',
'حرارت',
'حرکات',
'حلزون',
'حلقوم',
'درگیر',
'درمان',
'درنده',
'درونی',
'درویش',
'دریچه',
'دریدن',
'دریده',
'دریغا',
'دزدکی',
'دژبان',
'دژخیم',
'دژنام',
'دستار',
'دستاق',
'دستان',
'دستخط',
'دسترس',
'دستور',
'دسیسه',
'دشپیل',
'دشمنی',
'دشنام',
'دشوار',
'دغدغه',
'دفینه',
'دقیقه',
'دکتری',
'دلالت',
'دلاور',
'دلایل',
'دلبری',
'دلتنگ',
'دلچسب',
'دلخور',
'دلدار',
'دلربا',
'دلزده',
'دلسرد',
'دلسوز',
'دلشاد',
'دلشده',
'دلگرم',
'دلگشا',
'دلگیر',
'دلهره',
'دلیری',
'دمادم',
'دمامه',
'دمبدم',
'دمخور',
'دمساز',
'دمیدن',
'دنایت',
'دناست',
'دنبال',
'دندان',
'دنیوی',
'دوبین',
'دودکش',
'دودلی',
'دوران',
'دورگه',
'دورنگ',
'دوستی',
'دوسیه',
'دوشین',
'دوقلو',
'دویدن',
'دهانه',
'دهخدا',
'دهقان',
'دهکده',
'دهلیز',
'دیانت',
'دیپلم',
'دیجور',
'دیدار',
'دیرپا',
'دیرین',
'دیکته',
'دیلاق',
'دینار',
'دینام',
'دیوار',
'دیوان',
'ذایقه',
'ذخیره',
'ذرایع',
'ذکاوت',
'ذمیمه',
'رابطه',
'راحتی',
'راستا',
'راسته',
'راستی',
'راندن',
'رانده',
'راهبر',
'راهرو',
'سوگلی',
'سوگند',
'سهمیه',
'سهولت',
'سییات',
'سیاحت',
'سیادت',
'سیاره',
'سیاست',
'سیاسی',
'سیاهه',
'سیاهی',
'مهاجر',
'مهارت',
'مهتاب',
'مهجور',
'مهریه',
'مهشید',
//City Names
'خلخال',
'سرعین',
'انارک',
'تودشک',
'تیران',
'داران',
'دامنه',
'زواره',
'سمیرم',
'شهرضا',
'کاشان',
'کمشچه',
'گرگاب',
'گلدشت',
'گلشهر',
'مشکات',
'نایین',
'نیاسر',
'ورزنه',
'وزوان',
'آسارا',
'ایلام',
'ایوان',
'توحید',
'دلگشا',
'لومار',
'مهران',
'میمه ',
'آقکند',
'تبریز',
'خامنه',
'خراجو',
'خواجه',
'شبستر',
'کلیبر',
'گوگان',
'لیلان',
'مراغه',
'ملکان',
'ممقان',
'میانه',
'یامچی',
'باروق',
'بوکان',
'پلدشت',
'سردشت',
'سلماس',
'قوشچی',
'نالوس',
'نوشین',
'آبپخش',
'آبدان',
'بوشهر',
'چغادک',
'دالکی',
'دلوار',
'سیراف',
'آبسرد',
'آبعلی',
'بومهن',
'پردیس',
'پیشوا',
'تجریش',
'تهران',
'رودهن',
'کیلان',
'ملارد',
'آلونی',
'بروجن',
'چلگرد',
'سامان',
'ناغان',
'اسدیه',
'اسفدن',
'فردوس',
'انابد',
'باخرز',
'بیدخت',
'جغتای',
'رضویه',
'روداب',
'سلامی',
'سنگان',
'ششتمد',
'شهرزو',
'طرقبه',
'قوچان',
'کاریز',
'کاشمر',
'یونسی',
'جاجرم',
'شوقان',
'فاروج',
'لوجلی',
'الوان',
'اهواز',
'بستان',
'چمران',
'دزفول',
'سالند',
'سماله',
'شاوور',
'شرافت',
'شوشتر',
'شیبان',
'صیدون',
'گتوند',
'گوریه',
'هفتگل',
'هویزه',
'آب بر',
'چورزق',
'زنجان',
'قیدار',
'گرماب',
'بسطام',
'دیباج',
'سمنان',
'میامی',
'ادیمی',
'اسپکه',
'بزمان',
'بمپور',
'بنجار',
'پیشین',
'زابلی',
'سرباز',
'سوران',
'کنارک',
'محمدی',
'هیدوج',
'اقلید',
'آباده',
'حسامی',
'خرامه',
'داراب',
'دژکرد',
'رونیز',
'زرقان',
'سورمق',
'سیدان',
'شیراز',
'فدامی',
'لامرد',
'لپویی',
'لطیفی',
'مشکان',
'مصیری',
'میمند',
'نوجین',
'نودان',
'وراوی',
'ارداق',
'الوند',
'آبگرم',
'قزوین',
'کوهین',
'قنوات',
'بیجار',
'چناره',
'زرینه',
'سنندج',
'شویشه',
'باغین',
'بروات',
'پاریز',
'جوپار',
'جیرفت',
'چترود',
'خانوک',
'شهداد',
'کرمان',
'کهنوج',
'گلباف',
'گلزار',
'ماهان',
'مردهک',
'ازگله',
'سرمست',
'سومار',
'نودشه',
'نوسود',
'هرسین',
'چیتاب',
'دهدشت',
'یاسوج',
'ترکمن',
'کلاله',
'گرگان',
'اسالم',
'رودسر',
'شلمان',
'کومله',
'لوشان',
'لیسار',
'ماسال',
'مرجقل',
'منجیل',
'هشتپر',
'الشتر',
'چقابل',
'دورود',
'آلاشت',
'بهشهر',
'چالوس',
'رامسر',
'رویان',
'زیرآب',
'شیرود',
'کیاسر',
'نوشهر',
'پرندک',
'خنداب',
'زاویه',
'ساروق',
'سنجان',
'شازند',
'محلات',
'نیمور',
'بیکاه',
'رویدر',
'سندرک',
'سیریک',
'میناب',
'برزول',
'جوکار',
'زنگنه',
'سرکان',
'فرسفج',
'ملایر',
'همدان',
'اشکذر',
'دیهوک',
'مروست',
'مهریز',
'ندوشن'
]