export const WORDS_MEDIUM = [
    'ابادی',
  'ابخیز',
  'ابدار',
  'ابدان',
  'ابراه',
  'ابریز',
  'ابستن',
  'ابکند',
  'یحتمل',
'مفترض',
'مفترق',
'مفتری',
'مفتضح',
'مفتقر',
'مفتکی',
'مفتوح',
'مفتول',
'مفتون',
'مفروز',
'مفروش',
'مفروض',
'مفروق',
'مفسده',
'مفعول',
'مفقود',
'مفلسی',
'مفلوج',
'مفلوک',
'مفنگی',
'مفهوم',
'مقابر',
'مقابل',
'مقاتل',
'مقارن',
'مقاصد',
'مقاطع',
'مقاله',
'مقامر',
'مقامه',
'مقاوم',
'مقبره',
'مقبول',
'مقتبس',
'مقتدا',
'مقتدر',
'مقترن',
'مقتصد',
'مقتضا',
'مقتضی',
'مقتول',
'مقدار',
'مقدمه',
'مقدور',
'مقراض',
'مقرری',
'مقرعه',
'مقرنس',
'مقروض',
'مقرون',
'مقسوم',
'مقصود',
'مقصور',
'مقطوع',
'مقلاد',
'مقلوب',
'مقنعه',
'مقننه',
'مقوله',
'مقهور',
'مقیاس',
'مکاتب',
'مکارم',
'مکاره',
'مکاری',
'مکاری',
'مکاسب',
'مکانت',
'مکاید',
'مکتبی',
'مکتسب',
'مکتشف',
'مکتوب',
'مکتوم',
'مکثار',
'مکرمت',
'مکروه',
'مکشوف',
'مکنون',
'مکیدن',
'مکینه',
'ملاحت',
'ملازم',
'ملاست',
'ملافه',
'ملاقه',
'ملالت',
'ملامت',
'ملاهی',
'ملایم',
'ملایی',
'ملبوس',
'ملتبس',
'ملتجا',
'ملتجی',
'ملتزم',
'ملتفت',
'ملتمس',
'ملتهب',
'ملحفه',
'ملحوظ',
'ملعبه',
'ملعنت',
'ملعون',
'ملفوظ',
'ملکوت',
'ملکوک',
'ملکول',
'ملکیت',
'ملموس',
'ملوان',
'ملودی',
'ملهوف',
'ممالک',
'ممتاز',
'ممتحن',
'ممتلی',
'ممتنع',
'ممدوح',
'ممدود',
'ممزوج',
'ممسکی',
'مملکت',
'مملوک',
'ممنوع',
'ممنون',
'ممهور',
'ممیزی',
'منابر',
'منابع',
'منادی',
'مناره',
'منازع',
'منازل',
'مناسب',
'مناسک',
'مناصب',
'مناطق',
'مناظر',
'مناعت',
'منافذ',
'منافع',
'منافق',
'منافی',
'مناقب',
'مناهج',
'مناهی',
'منبری',
'منبسط',
'منبعث',
'منتخب',
'منتزع',
'منتسب',
'منتشا',
'منتشر',
'منتصر',
'منتظر',
'منتظم',
'منتفع',
'منتفی',
'منتقد',
'منتقل',
'منتقم',
'منتها',
'منتهی',
'منثور',
'منجلی',
'منجمد',
'منحرف',
'منحصر',
'منحنی',
'منحوس',
'منحول',
'مندرج',
'مندرس',
'مندوب',
'مندیل',
'منزجر',
'منزلت',
'منزوی',
'منسجم',
'منسوب',
'منسوج',
'منسوخ',
'منشات',
'منشعب',
'منشور',
'منصرف',
'منصرم',
'منصفت',
'منصوب',
'منصور',
'منضبط',
'منطبق',
'منطفی',
'منطقه',
'منطقی',
'منطوق',
'منطوی',
'منظره',
'منظور',
'منظوم',
'منعطف',
'منعقد',
'منعکس',
'منفجر',
'منفصل',
'منفعت',
'منفعل',
'منفور',
'منقاد',
'منقار',
'منقاش',
'منقبت',
'منقبض',
'منقرض',
'منقسم',
'منقصت',
'منقضی',
'منقطع',
'منقلب',
'منقوش',
'منقوط',
'منقول',
'منکسر',
'منکوب',
'منکوس',
'منوال',
'منهدم',
'منهزم',
'مواجب',
'مواجه',
'موارد',
'موازی',
'مواشی',
'مواضع',
'مواطن',
'مواظب',
'مواعظ',
'موافق',
'مواقف',
'موالی',
'موانع',
'مواهب',
'موبور',
'موتور',
'موثوق',
'موجود',
'موچین',
'موروث',
'موزون',
'موزیک',
'موسمی',
'موسوم',
'موسوی',
'موسیر',
'موصوف',
'موصول',
'موضوع',
'موعدی',
'موعظه',
'موعود',
'موفور',
'موقتی',
'موقوف',
'موکول',
'مولود',
'مومیا',
'موهبت',
'موهوب',
'موهوم',
'مویان',
'مهابت',
'مهاجر',
'مهارت',
'مهالک',
'مهتاب',
'مهتری',
'مهجور',
'مهدوم',
'مهریه',
'مهزوم',
'مهشید',
'مهلکه',
'مهمات',
'مهمان',
'مهملی',
'مهموز',
'مهموم',
'مهندس',
'مهیمن',
'میانه',
'میترا',
'میثاق',
'میخوش',
'میدان',
'میراب',
'میراث',
'میزان',
'میسره',
'میسور',
'میشوم',
'میعاد',
'میعان',
'میقات',
'میکده',
'میکرب',
'میگون',
'میلاد',
'میمنت',
'میمنه',
'میمون',
'مینوت',
'موالف',
'موتلف',
'موتمر',
'موتمن',
'موخره',
'موسسه',
'ناامن',
'نااهل',
'ناباب',
'نابجا',
'نابغه',
'نابلد',
'نابود',
'ناپاک',
'ناجنس',
'ناجور',
'ناچار',
'ناچیز',
'ناحیت',
'ناحیه',
'ناخدا',
'ناخرم',
'ناخلف',
'ناخوش',
'نادار',
'نادان',
'نادره',
'نارسا',
'ناروا',
'نازان',
'نازکی',
'ناساز',
'ناسره',
'ناسزا',
'ناسور',
'ناشاد',
'ناشتا',
'ناشکر',
'ناصاف',
'ناصیه',
'ناطقه',
'ناطور',
'ناقلا',
'ناقوس',
'ناکام',
'ناکسی',
'ناگاه',
'نالان',
'نامجو',
'نامرد',
'نامزد',
'نامور',
'ناموس',
'نامیه',
'نانوا',
'ناورد',
'ناهار',
'ناهید',
'نایاب',
'نایره',
'نبراس',
'نبرده',
'نبشته',
'نبهره',
'نبیره',
'نپخته',
'نتیجه',
'نجابت',
'نجاست',
'نجومی',
'نحوست',
'نخاله',
'نخجیر',
'نداری',
'ندافی',
'ندامت',
'نداوت',
'ندیمه',
'نرگسه',
'نرمال',
'نرموک',
'نرینه',
'نزاکت',
'نزدیک',
'نژاده',
'نژندی',
'نساجی',
'نستوه',
'نسناس',
'نسوان',
'نسیان',
'نشانه',
'نشانی',
'نشدنی',
'نشریه',
'نشستن',
'نشیمن',
'نصارا',
'نصیبه',
'نصیحت',
'نضارت',
'نظارت',
'نظاره',
'نظافت',
'نظامی',
'نظریه',
'نظمیه',
'نعلین',
'نعومت',
'نفاست',
'نفتکش',
'نفرین',
'نقابت',
'نقادی',
'نقاره',
'نقاشی',
'نقاضت',
'نقصان',
'نقمات',
'نقیصه',
'نکورو',
'نکوهش',
'نگارش',
'نگاره',
'نگران',
'نمامی',
'نمایش',
'نمدار',
'نمسار',
'نمکین',
'نمگین',
'نمناک',
'نمودن',
'نمونه',
'ننگین',
'نواور',
'نواحی',
'نواخت',
'نواده',
'نوازش',
'نواگر',
'نواله',
'نوایب',
'نوساز',
'نوسان',
'نوشتن',
'نوشته',
'نوشین',
'نوکار',
'نوگرا',
'نومید',
'نویسه',
'نهادن',
'نهالی',
'نهامی',
'نهانی',
'نهایت',
'نهایی',
'نهفتن',
'نهفته',
'نهمار',
'نیابت',
'نیایش',
'نیران',
'نیرنج',
'نیرنگ',
'نیزار',
'نیستی',
'نیشتر',
'نیکخو',
'نیکخو',
'نیوشا',
'واتگر',
'واجبی',
'وادار',
'وارسی',
'وارون',
'وازده',
'واسطه',
'واقعه',
'واقعی',
'واکنش',
'واگشت',
'واگیر',
'والده',
'واهمه',
'وثاقت',
'وثیقه',
'وجاهت',
'وجدان',
'وخشور',
'ودیعه',
'وراجی',
'وردست',
'ورودی',
'وزارت',
'وزیدن',
'وساده',
'وساطت',
'وسایط',
'وسایل',
'وسواس',
'وسوسه',
'وسیله',
'وصایت',
'وضعیت',
'وطواط',
'وظیفه',
'وقاحت',
'وقایت',
'وقایع',
'وکالت',
'وگرنه',
'ولادت',
'ولایت',
'ولخرج',
'ولگرد',
'ولوله',
'ولیمه',
'ویران',
'گفتگو',
'گلابی',
'گلایه',
'گلچین',
'گلرنگ',
'گلریز',
'گلزار',
'گلکار',
'گلگشت',
'گلگون',
'گلوله',
'گمانه',
'گمراه',
'گمشده',
'گمنام',
'گنجور',
'گنجشک',
'گنجفه',
'گنداب',
'گوارا',
'گوارش',
'گواهی',
'گودال',
'گورکن',
'گوریل',
'گوشزد',
'گوهری',
'گویچه',
'گوییا',
'گیلاس',
'گیلکی',
'لااقل',
'لاجرم',
'لازمه',
'لاغری',
'لاغیر',
'لاقید',
'لاهوت',
'لیامت',
'لباده',
'لبالب',
'لبخند',
'لبریز',
'لبلاب',
'لجاجت',
'لجباز',
'لرزان',
'لشکری',
'لطافت',
'لطیفه',
'لعنتی',
'لغایت',
'لغزان',
'لفافه',
'لکاته',
'لمعان',
'لواحق',
'لوازم',
'لوامه',
'لودگی',
'لوستر',
'لوندی',
'لیاقت',
'لیچار',
'لیلاج',
'لیوان',
'مایده',
'مابقی',
'مابین',
'ماتحت',
'ماترک',
'ماتیک',
'ماجرا',
'ماحصل',
'مادام',
'مادگی',
'مادون',
'مازاد',
'ماساژ',
'ماسبق',
'ماسلف',
'ماشرا',
'ماشین',
'مافوق',
'ماقبل',
'مالیه',
'مامان',
'ماندن',
'مانده',
'مانند',
'مانور',
'مانوی',
'ماورا',
'ماورد',
'ماوقع',
'ماهور',
'ماهیت',
'مابون',
'ماجور',
'ماخوذ',
'ماذنه',
'ماذون',
'ماکول',
'مالوف',
'مامور',
'ماموم',
'مامون',
'مانوس',
'مایوس',
'مباحث',
'مباحی',
'مبادا',
'مبادی',
'مبادی',
'مبارز',
'مبارک',
'مباشر',
'مبالغ',
'مبانی',
'مباهی',
'مبایع',
'مباین',
'مبتدا',
'مبتدع',
'مبتدی',
'مبتذل',
'مبتکر',
'مبتلا',
'مبتنی',
'مبتهج',
'مبذول',
'مبرات',
'مبرور',
'مبروص',
'مبرهن',
'مبعوث',
'مبهوت',
'متانت',
'متاثر',
'متاخر',
'متادب',
'متاذی',
'متاسف',
'متاسی',
'متالم',
'متاله',
'متاهل',
'متبحر',
'متبرک',
'متبسم',
'متبوع',
'متتبع',
'متجدد',
'متجسس',
'متجلی',
'متحجر',
'متحذر',
'متحرز',
'متحرک',
'متحصن',
'متحقق',
'متحلی',
'متحمل',
'متحول',
'متحیر',
'متحیز',
'متخصص',
'متخلف',
'متخلق',
'متدرج',
'متدین',
'متذکر',
'متذلل',
'متراژ',
'مترتب',
'مترجم',
'متردد',
'مترسک',
'مترسل',
'مترشح',
'مترصد',
'مترقب',
'مترقی',
'مترنم',
'متروک',
'متزهد',
'متشبث',
'متشتت',
'متشخص',
'متشرع',
'متشکر',
'متشکل',
'متشکی',
'متشنج',
'متصدی',
'متصرف',
'متصلب',
'متصلف',
'متصور',
'متضاد',
'متضرر',
'متضرع',
'متضمن',
'متظلم',
'متعال',
'متعبد',
'متعجب',
'متعدد',
'متعدی',
'متعذر',
'متعرض',
'متعزز',
'متعسر',
'متعسف',
'متعصب',
'متعفن',
'متعلق',
'متعلم',
'متعین',
'متغلب',
'متغیر',
'متفحص',
'متفرد',
'متفرس',
'متفرع',
'متفرق',
'متفطن',
'متفقه',
'متفکر',
'متقال',
'متقبل',
'متقدم',
'متقلب',
'متکبر',
'متکثر',
'متکدی',
'متکسر',
'متکفل',
'متکلف',
'متکلم',
'متلذذ',
'متلون',
'متلهف',
'متمتع',
'اغیار',
'افاده',
'افاقه',
'افترا',
'افراز',
'افراط',
'افزار',
'افزون',
'افساد',
'افسار',
'افسوس',
'افسون',
'افشان',
'افشره',
'افغان',
'افکار',
'افگار',
'افلاس',
'افلیج',
'افندی',
'افیون',
'اقارب',
'اقاله',
'اقامت',
'اقبال',
'اقتدا',
'اقتضا',
'اقتفا',
'اقدام',
'اقرار',
'اقران',
'اقربا',
'اقسام',
'اقلیت',
'اقلیم',
'اقناع',
'اقوام',
'اکابر',
'اکتفا',
'اکرام',
'اکراه',
'اکسید',
'اکسیر',
'اکمال',
'اکناف',
'اکنون',
'اگرچه',
'اگزما',
'البته',
'التقا',
'الحاح',
'الحاد',
'الحاق',
'الحال',
'الزام',
'القاح',
'القصه',
'النگو',
'الواط',
'الوان',
'الهام',
'امارت',
'اماکن',
'اماله',
'امامت',
'امانت',
'امتلا',
'امداد',
'امرار',
'امرود',
'امریه',
'امساک',
'امعان',
'امکان',
'املاک',
'امنیت',
'امنیه',
'اموال',
'امیری',
'انابت',
'انابه',
'انبار',
'انباز',
'انبان',
'انبوه',
'انتها',
'انتیم',
'انجام',
'انجمن',
'انجیر',
'انجین',
'انحنا',
'اندام',
'اندرز',
'اندکی',
'اندوه',
'انذار',
'انرژی',
'انزوا',
'انسان',
'انصاف',
'انعام',
'انعام',
'انفیه',
'انقضا',
'انکار',
'انگار',
'انگشت',
'انگور',
'انواع',
'اوایل',
'اوباش',
'اوراد',
'اورنگ',
'اوقات',
'اولاد',
'اولین',
'اوهام',
'اهالی',
'اهانت',
'اهلیت',
'اهمال',
'اهمیت',
'ایادی',
'ایثار',
'ایجاد',
'ایجاز',
'ایذاء',
'ایراد',
'ایزدی',
'ایضاح',
'ایفاد',
'ایقاع',
'ایقان',
'ایکاش',
'ایلچی',
'ایمان',
'ایمنی',
'اینجا',
'ایوان',
'ایهام',
'باادب',
'بااصل',
'بابزن',
'باتری',
'باتون',
'باثمر',
'باحال',
'باحزم',
'باحیا',
'باخبر',
'باختر',
'باختن',
'باخته',
'باخدا',
'باخود',
'بادپا',
'بادفر',
'بادیه',
'باذوق',
'باران',
'باربر',
'بارقه',
'بارکش',
'بارگی',
'بارور',
'بارها',
'باریک',
'بازار',
'بازجو',
'بازده',
'بازرس',
'بازگو',
'باسمه',
'باسیل',
'باشرف',
'باصره',
'باصفا',
'باطری',
'باطله',
'باعزم',
'باعفت',
'باغچه',
'بافتن',
'بافته',
'بافهم',
'باقلی',
'باکره',
'بالار',
'بالان',
'بالشت',
'بالکن',
'بالنگ',
'بالین',
'بامزه',
'بامهر',
'بانظم',
'بانکی',
'بانمک',
'باهمت',
'باهنر',
'باهوش',
'بتکده',
'بتونی',
'بتیار',
'بحران',
'بحیره',
'بخاری',
'بخردی',
'بخوبر',
'بداخم',
'بدادا',
'بداصل',
'بداغر',
'بداوت',
'بداهت',
'بدایت',
'بدایع',
'بدبخت',
'بدبدک',
'بدبده',
'ازگیل',
'اژدها',
'اسارت',
'اساسی',
'اسباب',
'اسباط',
'اسپرز',
'اسپرم',
'استاد',
'استخر',
'استیل',
'اسراف',
'اسطقس',
'اسفنج',
'اسفند',
'اسقاط',
'اسکان',
'اسکلت',
'اسکله',
'اسلاف',
'خلخال',
'سرعین',
'انارک',
'تودشک',
'تیران',
'داران',
'دامنه',
'زواره',
'سمیرم',
'شهرضا',
'کاشان',
'کمشچه',
'گرگاب',
'گلدشت',
'گلشهر',
'مشکات',
'نایین',
'نیاسر',
'ورزنه',
'وزوان',
'آسارا',
'ایلام',
'ایوان',
'توحید',
'دلگشا',
'لومار',
'مهران',
'میمه ',
'آقکند',
'تبریز',
'خامنه',
'خراجو',
'خواجه',
'شبستر',
'کلیبر',
'گوگان',
'لیلان',
'مراغه',
'ملکان',
'ممقان',
'میانه',
'یامچی',
'باروق',
'بوکان',
'پلدشت',
'سردشت',
'سلماس',
'قوشچی',
'نالوس',
'نوشین',
'آبپخش',
'آبدان',
'بوشهر',
'چغادک',
'دالکی',
'دلوار',
'سیراف',
'آبسرد',
'آبعلی',
'بومهن',
'پردیس',
'پیشوا',
'تجریش',
'تهران',
'رودهن',
'کیلان',
'ملارد',
'آلونی',
'بروجن',
'چلگرد',
'سامان',
'ناغان',
'اسدیه',
'اسفدن',
'فردوس',
'انابد',
'باخرز',
'بیدخت',
'جغتای',
'رضویه',
'روداب',
'سلامی',
'سنگان',
'ششتمد',
'شهرزو',
'طرقبه',
'قوچان',
'کاریز',
'کاشمر',
'یونسی',
'جاجرم',
'شوقان',
'فاروج',
'لوجلی',
'الوان',
'اهواز',
'بستان',
'چمران',
'دزفول',
'سالند',
'سماله',
'شاوور',
'شرافت',
'شوشتر',
'شیبان',
'صیدون',
'گتوند',
'گوریه',
'هفتگل',
'هویزه',
'آب بر',
'چورزق',
'زنجان',
'قیدار',
'گرماب',
'بسطام',
'دیباج',
'سمنان',
'میامی',
'ادیمی',
'اسپکه',
'بزمان',
'بمپور',
'بنجار',
'پیشین',
'زابلی',
'سرباز',
'سوران',
'کنارک',
'محمدی',
'هیدوج',
'اقلید',
'آباده',
'حسامی',
'خرامه',
'داراب',
'دژکرد',
'رونیز',
'زرقان',
'سورمق',
'سیدان',
'شیراز',
'فدامی',
'لامرد',
'لپویی',
'لطیفی',
'مشکان',
'مصیری',
'میمند',
'نوجین',
'نودان',
'وراوی',
'ارداق',
'الوند',
'آبگرم',
'قزوین',
'کوهین',
'قنوات',
'بیجار',
'چناره',
'زرینه',
'سنندج',
'شویشه',
'باغین',
'بروات',
'پاریز',
'جوپار',
'جیرفت',
'چترود',
'خانوک',
'شهداد',
'کرمان',
'کهنوج',
'گلباف',
'گلزار',
'ماهان',
'مردهک',
'ازگله',
'سرمست',
'سومار',
'نودشه',
'نوسود',
'هرسین',
'چیتاب',
'دهدشت',
'یاسوج',
'ترکمن',
'کلاله',
'گرگان',
'اسالم',
'رودسر',
'شلمان',
'کومله',
'لوشان',
'لیسار',
'ماسال',
'مرجقل',
'منجیل',
'هشتپر',
'الشتر',
'چقابل',
'دورود',
'آلاشت',
'بهشهر',
'چالوس',
'رامسر',
'رویان',
'زیرآب',
'شیرود',
'کیاسر',
'نوشهر',
'پرندک',
'خنداب',
'زاویه',
'ساروق',
'سنجان',
'شازند',
'محلات',
'نیمور',
'بیکاه',
'رویدر',
'سندرک',
'سیریک',
'میناب',
'برزول',
'جوکار',
'زنگنه',
'سرکان',
'فرسفج',
'ملایر',
'همدان',
'اشکذر',
'دیهوک',
'مروست',
'مهریز',
'ندوشن'
]