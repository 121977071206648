import { BaseModal2 } from './BaseModal2'

type Props = {
  isOpen: boolean
  lastWord: string
  handleClose: () => void
}

export const UCubeModal = ({ isOpen, lastWord, handleClose }: Props) => {
  return (
    <BaseModal2
      width='100vw'
      title={`University Cube`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div
        id="ucube"
        style={{
          color: 'white',
          direction: 'ltr',

        }}
        className='w-full h-full flex flex-col items-center justify-center gap-4 bg-gray-700 p-4 rounded-lg overflow-y-auto overflow-x-hidden border-none'
      >
        <a href="https://www.universitycube.net" className="flex-1 min-w-fit min-h-full text-sm flex flex-col items-start justify-start gap-4 border-none px-2 py-2 leading-loose tracking-wide">
          <img src="https://www.universitycube.net/images/university.png" className='w-full aspect-video object-cover h-auto rounded-lg '
            alt="UniversityCube.net" />
          <p className='text-left'>
            Connect, collaborate, and excel with UniversityCube.net! Explore 13k+ universities from 197 countries.
            Access faculty, chat, teams, and manage knowledge effortlessly.

          </p>
          <p className='text-left'>
            With 350k+ US faculty profiles, unlock endless opportunities for academic growth and networking.
          </p>
          <p className='text-left font-extrabold'>
            Join today!
          </p>
        </a>

      </div>
    </BaseModal2>
  )
}
