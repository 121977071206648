export const WORDS_DIFFICULT = [
  'ابادی',
'ابخیز',
'ابدار',
'ابدان',
'ابراه',
'ابریز',
'ابستن',
'ابکند',
'ابگیر',
'ابونه',
'ابیار',
'اتشین',
'اتلیه',
'اخرین',
'اخشیج',
'اخوند',
'ادامس',
'ادمیت',
'ادینه',
'اذرخش',
'اذرین',
'اذوقه',
'ارامش',
'ارایش',
'ارشیو',
'ارمان',
'اروین',
'ازاده',
'ازادی',
'ازردن',
'ازرده',
'ازگار',
'ازمند',
'ازمون',
'اژانس',
'اسایش',
'استان',
'اسمان',
'اسودن',
'اسوده',
'اسوری',
'اسیمه',
'اشپزی',
'اشغال',
'اشفتن',
'اشفته',
'اشکار',
'اشکوب',
'اشیان',
'اغشتن',
'اغشته',
'افتاب',
'افرین',
'اقایی',
'اکندن',
'اکنده',
'اگاهی',
'الاله',
'الامد',
'الایش',
'البوم',
'الودن',
'الوده',
'الونک',
'اماده',
'امپول',
'امرزش',
'اموزش',
'امیزش',
'امیزه',
'انتیک',
'انقدر',
'انگاه',
'اواره',
'اوازه',
'اوردن',
'اویزش',
'اویزه',
'اهسته',
'اهمند',
'اهنگر',
'اینده',
'ایینه',
'اباحت',
'ابتدا',
'ابدال',
'ابدیت',
'ابراز',
'ابرام',
'ابریق',
'ابزار',
'ابصار',
'ابطال',
'ابلهی',
'ابلیس',
'ابواب',
'ابهام',
'اتابک',
'اتاقک',
'اتباع',
'اتحاد',
'اتراق',
'اتساع',
'اتصال',
'اتفاق',
'اتلاف',
'اتمام',
'اتهام',
'اتیکت',
'اثاثه',
'اثبات',
'اجابت',
'اجاره',
'اجازت',
'اجازه',
'اجامر',
'اجانب',
'اجبار',
'اجحاف',
'اجداد',
'اجلاس',
'اجمال',
'اجناس',
'اجنبی',
'احاطه',
'احاله',
'احداث',
'احراز',
'احساس',
'احسان',
'احسنت',
'احشام',
'احضار',
'احفاد',
'احلام',
'احمقی',
'احوال',
'اخاذی',
'اخبار',
'اختفا',
'اخراج',
'اخروی',
'اخطار',
'اخلاص',
'اخلاف',
'اخلاق',
'اخلال',
'اداره',
'اداری',
'ادامه',
'ادبار',
'ادخال',
'ادرار',
'ادراک',
'ادلال',
'ادوات',
'اذعان',
'اذهان',
'ارایه',
'ارابه',
'ارادت',
'اراده',
'اراذل',
'ارباب',
'ارتخا',
'ارتشا',
'ارتشی',
'ارتضا',
'ارتقا',
'ارجاع',
'ارحام',
'اردنگ',
'ارزاق',
'ارزان',
'ارسال',
'ارشاد',
'ارعاب',
'ارفاق',
'ارکان',
'ارگان',
'اروند',
'اریکه',
'ازاله',
'ازباب',
'ازجهت',
'ازگیل',
'اژدها',
'اسارت',
'اساسی',
'اسباب',
'اسباط',
'اسپرز',
'اسپرم',
'استاد',
'استخر',
'استیل',
'اسراف',
'اسطقس',
'اسفنج',
'اسفند',
'اسقاط',
'اسکان',
'اسکلت',
'اسکله',
'اسلاف',
'اسلحه',
'اسلوب',
'اسهال',
'اسیری',
'اشاره',
'اشاعه',
'اشباع',
'اشخاص',
'اشراف',
'اشراق',
'اشعار',
'اشغال',
'اشکال',
'اشکال',
'اشکوب',
'اصابت',
'اصالت',
'اصحاب',
'اصدار',
'اصرار',
'اصلاح',
'اضافه',
'اضافی',
'اضرار',
'اطاعت',
'اطاقک',
'اطاله',
'اطراف',
'اطعام',
'اطفال',
'اطلاع',
'اطلاق',
'اطناب',
'اطوار',
'اظهار',
'اعاده',
'اعاشه',
'اعانت',
'اعتلا',
'اعتنا',
'اعجاب',
'اعجاز',
'اعراض',
'اعراف',
'اعزاز',
'اعزام',
'اعسار',
'اعظام',
'اعقاب',
'اعلام',
'اعمال',
'اعیان',
'اغراق',
'اغفال',
'اغماض',
'اغیار',
'افاده',
'افاقه',
'افترا',
'افراز',
'افراط',
'افزار',
'افزون',
'افساد',
'افسار',
'افسوس',
'افسون',
'افشان',
'افشره',
'افغان',
'افکار',
'افگار',
'افلاس',
'افلیج',
'افندی',
'افیون',
'اقارب',
'اقاله',
'اقامت',
'اقبال',
'اقتدا',
'اقتضا',
'اقتفا',
'اقدام',
'اقرار',
'اقران',
'اقربا',
'اقسام',
'اقلیت',
'اقلیم',
'اقناع',
'اقوام',
'اکابر',
'اکتفا',
'اکرام',
'اکراه',
'اکسید',
'اکسیر',
'اکمال',
'اکناف',
'اکنون',
'اگرچه',
'اگزما',
'البته',
'التقا',
'الحاح',
'الحاد',
'الحاق',
'الحال',
'الزام',
'القاح',
'القصه',
'النگو',
'الواط',
'الوان',
'الهام',
'امارت',
'اماکن',
'اماله',
'امامت',
'امانت',
'امتلا',
'امداد',
'امرار',
'امرود',
'امریه',
'امساک',
'امعان',
'امکان',
'املاک',
'امنیت',
'امنیه',
'اموال',
'امیری',
'انابت',
'انابه',
'انبار',
'انباز',
'انبان',
'انبوه',
'انتها',
'انتیم',
'انجام',
'انجمن',
'انجیر',
'انجین',
'انحنا',
'اندام',
'اندرز',
'اندکی',
'اندوه',
'انذار',
'انرژی',
'انزوا',
'انسان',
'انصاف',
'انعام',
'انعام',
'انفیه',
'انقضا',
'انکار',
'انگار',
'انگشت',
'انگور',
'انواع',
'اوایل',
'اوباش',
'اوراد',
'اورنگ',
'اوقات',
'اولاد',
'اولین',
'اوهام',
'اهالی',
'اهانت',
'اهلیت',
'اهمال',
'اهمیت',
'ایادی',
'ایثار',
'ایجاد',
'ایجاز',
'ایذاء',
'ایراد',
'ایزدی',
'ایضاح',
'ایفاد',
'ایقاع',
'ایقان',
'ایکاش',
'ایلچی',
'ایمان',
'ایمنی',
'اینجا',
'ایوان',
'ایهام',
'باادب',
'بااصل',
'بابزن',
'باتری',
'باتون',
'باثمر',
'باحال',
'باحزم',
'باحیا',
'باخبر',
'باختر',
'باختن',
'باخته',
'باخدا',
'باخود',
'بادپا',
'بادفر',
'بادیه',
'باذوق',
'باران',
'باربر',
'بارقه',
'بارکش',
'بارگی',
'بارور',
'بارها',
'باریک',
'بازار',
'بازجو',
'بازده',
'بازرس',
'بازگو',
'باسمه',
'باسیل',
'باشرف',
'باصره',
'باصفا',
'باطری',
'باطله',
'باعزم',
'باعفت',
'باغچه',
'بافتن',
'بافته',
'بافهم',
'باقلی',
'باکره',
'بالار',
'بالان',
'بالشت',
'بالکن',
'بالنگ',
'بالین',
'بامزه',
'بامهر',
'بانظم',
'بانکی',
'بانمک',
'باهمت',
'باهنر',
'باهوش',
'بتکده',
'بتونی',
'بتیار',
'بحران',
'بحیره',
'بخاری',
'بخردی',
'بخوبر',
'بداخم',
'بدادا',
'بداصل',
'بداغر',
'بداوت',
'بداهت',
'بدایت',
'بدایع',
'بدبخت',
'بدبدک',
'بدبده',
'بدبین',
'بدجنس',
'بدچشم',
'بدحال',
'بدخلق',
'بدخیم',
'بدذات',
'بدرام',
'بدراه',
'بدرای',
'بدرزق',
'بدرقه',
'بدرود',
'بدروش',
'بدشکل',
'بدصفت',
'بدطعم',
'بدعمل',
'بدعنق',
'بدعهد',
'بدغذا',
'بدفعل',
'بدفکر',
'بدقدم',
'بدقلق',
'بدقول',
'بدکار',
'بدکنش',
'بدکیش',
'بدکین',
'بدگلی',
'بدلقا',
'بدمزه',
'بدمست',
'بدمنش',
'بدمهر',
'بدنام',
'بدنسل',
'بدنما',
'بدنیت',
'بدویت',
'بدهضم',
'بدیمن',
'بدیهی',
'برایت',
'برابر',
'برادر',
'برازخ',
'براعت',
'برایا',
'برایی',
'برتری',
'برجیس',
'برچسب',
'برحسب',
'برخور',
'بردار',
'بردار',
'بردگی',
'بررسی',
'برزگر',
'برشتن',
'برشته',
'برطبق',
'برطرف',
'برعکس',
'برکشی',
'برگچه',
'برگشت',
'برملا',
'برمنش',
'برنده',
'برنده',
'برنزه',
'بروبر',
'برودت',
'بروفق',
'برهان',
'برهمن',
'برهنه',
'برهوت',
'بریات',
'بریان',
'بریدن',
'بریده',
'بریزن',
'بریشم',
'بزازی',
'بزدلی',
'بزرگی',
'بساطت',
'بسامد',
'بساوش',
'بستان',
'بستری',
'بستگی',
'بستوه',
'بسنده',
'بسودن',
'بسیار',
'بشارت',
'بشاشت',
'بشخصه',
'بشریت',
'بشقاب',
'بشکوه',
'بصارت',
'بصیرت',
'بضاعت',
'بطالت',
'بطلان',
'بعینه',
'بغاوت',
'بغایت',
'بقالی',
'بقایا',
'بکارت',
'بکمال',
'بلاده',
'بلاغت',
'بلاکش',
'بلاهت',
'بلایا',
'بلبشو',
'بلدان',
'بلدیه',
'بلندا',
'بلندی',
'بلوار',
'بلهوس',
'بمورد',
'بموقع',
'بنادر',
'بنچاق',
'بندشی',
'بندگی',
'بنزین',
'بنفسه',
'بنگاه',
'بنلاد',
'بنیاد',
'بنیان',
'بوادی',
'بوارق',
'بواطن',
'بواعث',
'بوبین',
'بوتیک',
'بودار',
'بودجه',
'بوران',
'بوریا',
'بوگان',
'بومهن',
'بهادر',
'بهاره',
'بهانه',
'بهایم',
'بهایی',
'بهبود',
'بهدین',
'بهرام',
'بهروز',
'بهشتی',
'بهمان',
'بهیار',
'بهیمه',
'بهیمی',
'بیختن',
'بیخته',
'بیداد',
'بیدار',
'بیرون',
'بیزار',
'بیسار',
'بیشتر',
'بیضوی',
'بیطار',
'بیغار',
'بیگار',
'بیلان',
'بیمار',
'بینات',
'بیوگی',
'پابست',
'پابند',
'پاپوش',
'پاتوق',
'پاتیل',
'پاچین',
'پاداش',
'پارتی',
'پارچه',
'پارسا',
'پارسی',
'پارگی',
'پازهر',
'پاساژ',
'پاسوز',
'پاکار',
'پاکوب',
'پاگون',
'پاگیر',
'پالار',
'پالیز',
'پایان',
'پاییز',
'پایین',
'پچل 1',
'پختگی',
'پدرام',
'پدیده',
'پذیرا',
'پذیرش',
'پربها',
'پرپشت',
'پرپیچ',
'پرتاب',
'پرتاب',
'پرتره',
'پرثمر',
'پرجگر',
'پرچین',
'پرحجم',
'پرحرف',
'پرخاش',
'پرخور',
'پردگی',
'پردیس',
'پررنج',
'پررنگ',
'پرزور',
'پرستش',
'پرستو',
'پرسخن',
'پرسنل',
'پرسود',
'پرشکن',
'پرشور',
'پرقوت',
'پرکار',
'پرناز',
'پرنده',
'پرنقش',
'پروار',
'پرواز',
'پرورش',
'پروزن',
'پروژه',
'پروین',
'پرهیز',
'پریرو',
'پریشب',
'پریشی',
'پزشکی',
'پزنده',
'پژمان',
'پژواک',
'پژوهش',
'پستان',
'پستچی',
'پسروی',
'پشیزه',
'پفیوز',
'پلیدی',
'پنجره',
'پندار',
'پندگو',
'پنهان',
'پوسته',
'پوشاک',
'پولاد',
'پیاپی',
'پیاله',
'پیامد',
'پیروز',
'پیروی',
'پیرهن',
'پیشاب',
'پیشتر',
'پیشرس',
'پیشرو',
'پیشکش',
'پیشگو',
'پیشوا',
'پیشین',
'پیکار',
'پیکان',
'پیکره',
'پیمان',
'پیوست',
'پیوند',
'تیاتر',
'تیوری',
'تابان',
'تابلو',
'تابوت',
'تاتار',
'تاجور',
'تاختن',
'تاراج',
'تارزن',
'تاریخ',
'تاریک',
'تازگی',
'تازیک',
'تافتن',
'تافته',
'تالاب',
'تالار',
'تالان',
'تانکر',
'تاوان',
'تاثیر',
'تاخیر',
'تادیب',
'تادیه',
'تاسیس',
'تاکید',
'تالیف',
'تامین',
'تانیس',
'تاویل',
'تایید',
'تبادر',
'تبادل',
'تبادل',
'تبارک',
'تباعد',
'تبانی',
'تباهی',
'تباین',
'تبجیل',
'تبختر',
'تبخیر',
'تبدیل',
'تبذیر',
'تبریه',
'تبرزد',
'تبریک',
'تبشیر',
'تبصره',
'تبعات',
'تبعیت',
'تبعید',
'تبعیض',
'تبلور',
'تبلیغ',
'تبیین',
'تپاله',
'تتمیم',
'تثبیت',
'تثلیث',
'تثنیه',
'تجارب',
'تجارت',
'تجاسر',
'تجانس',
'تجاوز',
'تجاهر',
'تجاهل',
'تجدید',
'تجربه',
'تجربی',
'تجرید',
'تجزیه',
'تجلید',
'تجلیل',
'تجنیس',
'تجوید',
'تجویز',
'تجهیز',
'تحاشی',
'تحبیب',
'تحدید',
'تحذیر',
'تحریر',
'تحریص',
'تحریض',
'تحریف',
'تحریک',
'تحریم',
'تحسین',
'تحشیه',
'تحصیل',
'تحفگی',
'تحقیر',
'تحکیم',
'تحلیف',
'تحلیل',
'تحمید',
'تحمیق',
'تحمیل',
'تحویل',
'تحیات',
'تخاصم',
'تخالف',
'تخدیر',
'تخریب',
'تخصیص',
'تخطیه',
'تخفیف',
'تخلخل',
'تخلیه',
'تخمیر',
'تخمین',
'تخویف',
'تخیلی',
'تخییل',
'تدارک',
'تداعی',
'تدافع',
'تداول',
'تداوم',
'تداوی',
'تدبیر',
'تدخین',
'تدفین',
'تدقیق',
'تدلیس',
'تدمیر',
'تدویر',
'تدوین',
'تدهین',
'تذبذب',
'تذکار',
'تذکره',
'تذکیر',
'تذکیه',
'تذهیب',
'تراجم',
'تراخم',
'تراخی',
'ترادف',
'ترازو',
'تراشه',
'تراضی',
'تراکم',
'ترانه',
'تراوش',
'تربیت',
'ترتیب',
'ترتیل',
'ترجمه',
'ترجیح',
'ترجیع',
'ترجیه',
'ترحیب',
'ترحیم',
'ترخیص',
'ترخیم',
'تردست',
'تردید',
'ترسان',
'ترسیم',
'ترصیع',
'ترعیب',
'ترغیب',
'ترفند',
'ترفیع',
'ترفیه',
'ترقوه',
'ترقیم',
'ترکیب',
'ترمیم',
'ترویج',
'ترهات',
'تریاق',
'تریاک',
'تزاحم',
'تزاید',
'تزریق',
'تزکیه',
'تزلزل',
'تزویج',
'تزویر',
'تزیین',
'تسامح',
'تساوی',
'تساهل',
'تسبیب',
'تسبیح',
'تسجیل',
'تسخیر',
'تسریع',
'تسطیح',
'تسعیر',
'تسکین',
'تسلسل',
'تسلیت',
'تسلیح',
'تسلیم',
'تسمیه',
'تسوید',
'تسویه',
'تسهیل',
'تسهیم',
'تشابه',
'تشبیه',
'تشجیع',
'تشحیذ',
'تشخیص',
'تشدید',
'تشریح',
'تشریف',
'تشریک',
'تشعشع',
'تشکیک',
'تشکیل',
'تشنگی',
'تشنیع',
'تشویر',
'تشویش',
'تشویق',
'تشیید',
'تشییع',
'تصاحب',
'تصادف',
'تصادم',
'تصاعد',
'تصحیح',
'تصحیف',
'تصدیع',
'تصدیق',
'تصریح',
'تصریف',
'تصعید',
'تصغیر',
'تصفیه',
'تصمیم',
'تصنعی',
'تصنیف',
'تصوری',
'تصویب',
'تصویر',
'تضاعف',
'تضامن',
'تضریب',
'تضریس',
'تضعیف',
'تضلیل',
'تضمین',
'تضییع',
'تضییق',
'تطابق',
'تطاول',
'تطبیق',
'تطمیع',
'تطویل',
'تطهیر',
'تظاهر',
'تظلیل',
'تعادل',
'تعارض',
'تعارف',
'تعاطی',
'تعاقب',
'تعالی',
'تعامل',
'تعاون',
'تعبیر',
'تعبیه',
'تعجیل',
'تعداد',
'تعدیل',
'تعذیب',
'تعذیر',
'تعرفه',
'تعریض',
'تعریف',
'تعریق',
'تعریک',
'تعزیت',
'تعزیر',
'تعزیه',
'تعطیل',
'تعظیم',
'تعقیب',
'تعقید',
'تعقیم',
'تعلیف',
'تعلیق',
'تعلیل',
'تعلیم',
'تعمیر',
'تعمیم',
'تعویذ',
'تعویض',
'تعویق',
'تعویل',
'تعییر',
'تعیین',
'تغابن',
'تغافل',
'تغایر',
'تغذیه',
'تغسیل',
'تغلیظ',
'تغییر',
'تفاخر',
'تفاضل',
'تفاله',
'تفاوت',
'تفاهم',
'تفتیش',
'تفتین',
'تفخیم',
'تفرعن',
'تفرقه',
'تفریح',
'تفریط',
'تفریق',
'تفسیر',
'تفسیق',
'تفصیل',
'تفضیح',
'تفضیل',
'تفکیک',
'تفویض',
'تفهیم',
'تقابل',
'تقارب',
'تقارن',
'تقاضا',
'تقاطع',
'تقاعد',
'تقبیح',
'تقبیل',
'تقدیر',
'تقدیم',
'تقریر',
'تقریض',
'تقریظ',
'تقسیط',
'تقسیم',
'تقشیر',
'تقصیر',
'تقطیر',
'تقطیع',
'تقلبی',
'تقلید',
'تقلیل',
'تقنین',
'تقویت',
'تقویم',
'تقیید',
'تکاپو',
'تکاثر',
'تکاثف',
'تکاسل',
'تکافو',
'تکاور',
'تکاهل',
'تکایا',
'تکبیر',
'تکثیر',
'تکذیب',
'تکرار',
'تکریر',
'تکریم',
'تکفین',
'تکلمه',
'تکلیف',
'تکمیل',
'تکنیک',
'تکوین',
'تکیدن',
'تکیده',
'تلایم',
'تلازم',
'تلاشی',
'تلاطم',
'تلافی',
'تلاقی',
'تلالو',
'تلاوت',
'تلبیس',
'تلبیه',
'تلخیص',
'تلطیف',
'تلفات',
'تلفیف',
'تلفیق',
'تلقیح',
'تلقین',
'تلمبه',
'تلمیح',
'تلمیذ',
'تمارض',
'تماشا',
'تمایز',
'تمایل',
'تمثال',
'تمثیل',
'تمجمج',
'تمجید',
'تمدید',
'تمرکز',
'تمرین',
'تمساح',
'تمسخر',
'تمشیت',
'تمکین',
'تمهید',
'تمهیل',
'تمیزی',
'تمییز',
'تنازع',
'تناسب',
'تناسخ',
'تناسل',
'تناظر',
'تنافر',
'تنافس',
'تناقض',
'تناوب',
'تناور',
'تناول',
'تنبان',
'تنبلی',
'تنبیه',
'تندپا',
'تندپو',
'تندخو',
'تندرو',
'تندیس',
'تنزیل',
'تنزیه',
'تنسیق',
'تنظیف',
'تنظیم',
'تنقیح',
'تنقید',
'تنقیه',
'تنگاب',
'تنگنا',
'تنوره',
'تنویر',
'تنیدن',
'تنیده',
'توابع',
'تواتر',
'تواجد',
'توارث',
'تواری',
'توازن',
'توازی',
'تواضع',
'توافق',
'توالت',
'توالد',
'توالی',
'توانا',
'توبره',
'توبیخ',
'توتیا',
'توجیه',
'توچال',
'توحید',
'تودار',
'تودیع',
'توزیع',
'توزین',
'توسعه',
'توسنی',
'توسیع',
'توشیح',
'توصیف',
'توصیه',
'توضیح',
'توطیه',
'توفان',
'توفیر',
'توفیق',
'توقیر',
'توقیع',
'توقیف',
'تولیت',
'تولید',
'تومان',
'تومور',
'تونیک',
'توهین',
'تهاتر',
'تهاجم',
'تهافت',
'تهاون',
'تهدید',
'تهذیب',
'تهلکه',
'تهلیل',
'تهمتن',
'تهنیت',
'تهییج',
'تیراژ',
'تیررس',
'تیرکش',
'تیرگی',
'تیزاب',
'تیزپو',
'تیزتک',
'تیزتک',
'تیزرو',
'تیفوس',
'تیماج',
'تیمار',
'تیمچه',
'ثانیه',
'ثلاثه',
'جادار',
'جاذبه',
'جارچی',
'جاسوس',
'جاکشی',
'جالیز',
'جامعه',
'جانان',
'جانور',
'جاوید',
'جبران',
'جبروت',
'جدایی',
'جراحت',
'جراید',
'جربزه',
'جریان',
'جریحه',
'جریده',
'جریمه',
'جزیره',
'جسارت',
'جسامت',
'جستار',
'جستجو',
'جعودت',
'جفاجو',
'جلالت',
'جلباب',
'جمعیت',
'جملگی',
'جمهور',
'جنازه',
'جنایت',
'جنجال',
'جنگجو',
'جندگی',
'جنگجو',
'جوارح',
'جوانب',
'جوانی',
'جواهر',
'جولان',
'جولاه',
'جوهره',
'جویدن',
'جهانی',
'جهودی',
'جهیدن',
'جیحون',
'جیران',
'چابکی',
'چاپار',
'چاپچی',
'چاپگر',
'چاچول',
'چاخان',
'چادری',
'چارپا',
'چارتر',
'چارسو',
'چارقد',
'چاروا',
'چاشنی',
'چاکری',
'چالاک',
'چامین',
'چاووش',
'چپانی',
'چپاول',
'چپوچی',
'چخماق',
'چخیدن',
'چراگه',
'چرایی',
'چرتکه',
'چرخاب',
'چرخان',
'چرخشی',
'چرکین',
'چرمین',
'چرنده',
'چریدن',
'چریکی',
'چشایی',
'چشیدن',
'چغاله',
'چغانه',
'چکامه',
'چکاوک',
'چکیدن',
'چکیده',
'چگالی',
'چگونه',
'چلتوک',
'چلچله',
'چلچلی',
'چلنگر',
'چلیپا',
'چلیدن',
'چمانه',
'چمانی',
'چمدان',
'چمیدن',
'چنبره',
'چندان',
'چندین',
'چنگار',
'چنگال',
'چنگول',
'چوبین',
'چوپان',
'چوگان',
'چهچهه',
'چیرگی',
'چیستی',
'چیلان',
'حاجات',
'حادثه',
'حاشاک',
'حاشیه',
'حاصله',
'حاضری',
'حافظه',
'حالات',
'حالیا',
'حالیه',
'حامله',
'حباله',
'حبسیه',
'حتمیت',
'حجابت',
'حجاری',
'حجامت',
'حجرات',
'حداثت',
'حدادی',
'حداقل',
'حدایق',
'حدساً ',
'حدیده',
'حدیقه',
'حذاقت',
'حراثت',
'حرارت',
'حراست',
'حرامی',
'حرکات',
'حرمان',
'حرمله',
'حروفی',
'حریری',
'حسابی',
'حسادت',
'حسبان',
'حسنات',
'حصانت',
'حصولی',
'حضارت',
'حضانت',
'حفاری',
'حفاظت',
'حقارت',
'حقانی',
'حقایق',
'حقیقت',
'حقیقی',
'حکاکی',
'حکایت',
'حکمیت',
'حکومت',
'حلاجی',
'حلاوت',
'حلزون',
'حلقوم',
'حلولی',
'حلیله',
'حماسه',
'حماسی',
'حماقت',
'حمالی',
'حمامه',
'حمامی',
'حمایت',
'حماید',
'حمایل',
'حمدان',
'حمیده',
'حنانه',
'حنجره',
'حوادث',
'حواری',
'حواشی',
'حواله',
'حوالی',
'حوروش',
'حوزوی',
'حوصله',
'حوضچه',
'حیاتی',
'حیثیت',
'حیران',
'حیوان',
'خاتمه',
'خاتون',
'خاخام',
'خادمه',
'خارجه',
'خارجی',
'خاستن',
'خاشاک',
'خاصره',
'خاصگی',
'خاصیت',
'خاطره',
'خاموش',
'خانگی',
'خاوند',
'خباثت',
'خبازی',
'خبایا',
'خبرکش',
'خبرگی',
'ختلان',
'خجالت',
'خجسته',
'خدایی',
'خدمات',
'خدیعت',
'خدیور',
'خذلان',
'خرابه',
'خرابی',
'خراطی',
'خرافه',
'خرافی',
'خرامش',
'خرپول',
'خرجین',
'خرچنگ',
'خرخره',
'خرخشه',
'خردلی',
'خرسند',
'خرطوم',
'خرفتی',
'خرکار',
'خرکچی',
'خرگاه',
'خرگوش',
'خرمست',
'خرناس',
'خروار',
'خروجی',
'خریدن',
'خریطه',
'خریفی',
'خزانه',
'خزاین',
'خزیدن',
'خزینه',
'خسارت',
'خستگی',
'خسران',
'خشخاش',
'خشنود',
'خشونت',
'خصایل',
'خصوصی',
'خصومت',
'خطابه',
'خطایا',
'خطییه',
'خفایا',
'خفقان',
'خلاصه',
'خلاصی',
'خلاعت',
'خلافت',
'خلایق',
'خلبان',
'خلجان',
'خلوتی',
'خلیدن',
'خلیفه',
'خماری',
'خماسی',
'خمکده',
'خمناک',
'خموشی',
'خمیدن',
'خمیده',
'خمیره',
'خندان',
'خنزیر',
'خواجه',
'خوارق',
'خواری',
'خوازه',
'خواست',
'خوانا',
'خوانش',
'خواهر',
'خواهش',
'خودرو',
'خودرو',
'خودسر',
'خوراک',
'خوردن',
'خورند',
'خوشاب',
'خوشکل',
'خوگیر',
'خوناب',
'خونین',
'خویشی',
'خیاطی',
'خیالی',
'خیانت',
'خیرگی',
'خیزاب',
'خیشوم',
'خیمگی',
'داخلی',
'دادار',
'داداش',
'دادرس',
'دادگر',
'دادور',
'داعیه',
'داغان',
'دالان',
'دامان',
'دامنه',
'داوری',
'داهیه',
'دایره',
'دبدبه',
'دبیری',
'دخالت',
'دختری',
'درامد',
'درازا',
'درازی',
'دراست',
'درایت',
'درباب',
'دربار',
'دربان',
'دربدر',
'دربند',
'درجات',
'درخشش',
'درخفا',
'درخور',
'درداب',
'دردسر',
'درستی',
'درشتی',
'درشکه',
'درضمن',
'درگاه',
'درگرو',
'درگیر',
'درمان',
'درنده',
'درونی',
'درویش',
'دریچه',
'دریدن',
'دریده',
'دریغا',
'دزدکی',
'دژبان',
'دژخیم',
'دژنام',
'دستار',
'دستاق',
'دستان',
'دستخط',
'دسترس',
'دستور',
'دسیسه',
'دشپیل',
'دشمنی',
'دشنام',
'دشوار',
'دغدغه',
'دفینه',
'دقیقه',
'دکتری',
'دلالت',
'دلاور',
'دلایل',
'دلبری',
'دلتنگ',
'دلچسب',
'دلخور',
'دلدار',
'دلربا',
'دلزده',
'دلسرد',
'دلسوز',
'دلشاد',
'دلشده',
'دلگرم',
'دلگشا',
'دلگیر',
'دلهره',
'دلیری',
'دمادم',
'دمامه',
'دمبدم',
'دمخور',
'دمساز',
'دمیدن',
'دنایت',
'دناست',
'دنبال',
'دندان',
'دنیوی',
'دوبین',
'دودکش',
'دودلی',
'دوران',
'دورگه',
'دورنگ',
'دوستی',
'دوسیه',
'دوشین',
'دوقلو',
'دویدن',
'دهانه',
'دهخدا',
'دهقان',
'دهکده',
'دهلیز',
'دیانت',
'دیپلم',
'دیجور',
'دیدار',
'دیرپا',
'دیرین',
'دیکته',
'دیگ 1',
'دیلاق',
'دینار',
'دینام',
'دیوار',
'دیوان',
'دیوثی',
'دیوچه',
'دیهیم',
'ذایقه',
'ذخیره',
'ذرایع',
'ذکاوت',
'ذمیمه',
'رابطه',
'راپرت',
'راحتی',
'راستا',
'راسته',
'راستی',
'راقیه',
'راندن',
'رانده',
'راهبر',
'راهرو',
'راهزن',
'رایحه',
'رایزن',
'رباعی',
'ربانی',
'ربایش',
'ربودن',
'رپرتر',
'رجحان',
'رختشو',
'رخداد',
'رخسار',
'رخشان',
'ردایت',
'ردشدن',
'رذالت',
'رذیلت',
'رزانت',
'رسالت',
'رساله',
'رسانا',
'رسایی',
'رسیدن',
'رسیده',
'رشادت',
'رشکین',
'رضایت',
'رضوان',
'رطوبت',
'رعایت',
'رفاقت',
'رفتار',
'رفتگر',
'رفتنی',
'رفوزه',
'رفیقه',
'رقابت',
'رقاصی',
'رقیمه',
'رگزنی',
'رمالی',
'رمنده',
'رمیدن',
'رمیده',
'رنجبر',
'رنجور',
'رنگرز',
'رنگین',
'روانه',
'روانی',
'روایت',
'روایی',
'روبان',
'روبرو',
'روبند',
'روزنه',
'روسپی',
'روستا',
'روسری',
'روشنی',
'رونده',
'رهایش',
'رهایی',
'رهبری',
'رهگذر',
'رهنما',
'رهوار',
'رهیدن',
'رهینه',
'ریاست',
'ریاضت',
'زاپاس',
'زانیه',
'زاویه',
'زباله',
'زبانه',
'زبانی',
'زبرین',
'زبونی',
'زدایش',
'زدودن',
'زدوده',
'زربفت',
'زراعت',
'زرداب',
'زردشت',
'زرنگی',
'زعامت',
'زلزله',
'زمانه',
'زمختی',
'زمزمه',
'زمینه',
'زمینی',
'زنبیل',
'زنجیر',
'زندان',
'زندقه',
'زندگی',
'زندیق',
'زنگار',
'زننده',
'زنهار',
'زوبین',
'زودرس',
'زورکی',
'زورگو',
'زهدان',
'زهراب',
'زهوار',
'زیادت',
'زیادی',
'زیارت',
'زیرکی',
'زیرین',
'ژاژخا',
'ژرفنا',
'ژنتیک',
'ژنرال',
'سیانس',
'سیانس',
'ساباط',
'سابقه',
'ساچمه',
'ساحره',
'ساحری',
' ساخت',
'ساخته',
'ساخلو',
'سادات',
'سادگی',
'ساروق',
'ساززن',
'سازور',
'ساطور',
'ساغری',
'سالار',
'سالکی',
'سالوس',
'سامان',
'سامعه',
'سانحه',
'ساویز',
'ساویس',
'سبابه',
'سباحت',
'سباعی',
'سبحات',
'سبحان',
'سبعیت',
'سبیلو',
'سپاهی',
'سپردن',
'سپرده',
'سپهبد',
'سپیده',
'سپیدی',
'ستادن',
'ستاره',
'ستایش',
'ستبرا',
'ستبری',
'ستردن',
'سترده',
'سترون',
'ستمگر',
'ستوان',
'ستودن',
'ستوده',
'ستوهی',
'ستیزه',
'سجاده',
'سجایا',
'سحرگر',
'سخافت',
'سخاوت',
'سخریه',
'سخنور',
'سدانت',
'سدشکن',
'سرامد',
'سراپا',
'سراچه',
'سرادق',
'سراسر',
'سرایت',
'سرایر',
'سرایش',
'سرایه',
'سربار',
'سرباز',
'سرپوش',
'سرپوش',
'سرتیپ',
'سرتیر',
'سرجمع',
'سرچسب',
'سرحال',
'سرخاب',
'سرخود',
'سرخوش',
'سرخیل',
'سرداب',
'سردار',
'سردرد',
'سرزده',
'سرزنش',
'سرسام',
'سرسبد',
'سرسبز',
'سرسخت',
'سرسرا',
'سرسری',
'سرشار',
'سرشتن',
'سرشته',
'سرشیر',
'سرطان',
'سرفصل',
'سرکشی',
'سرکوب',
'سرگرا',
'سرگرد',
'سرگرم',
'سرگیس',
'سرگین',
'سرمدی',
'سرمست',
'سرمشق',
'سرنام',
'سروال',
'سروان',
'سروپا',
'سروپز',
'سرودن',
'سروری',
'سروسر',
'سروقت',
'سروقد',
'سرومر',
'سرویس',
'سریال',
'سریال',
'سریان',
'سریدن',
'سریرت',
'سعادت',
'سعایت',
'سعتری',
'سفارت',
'سفارش',
'سفاهت',
'سفاین',
'سفسطه',
'سفلگی',
'سفلیس',
'سفیده',
'سفینه',
'سقایت',
'سقایه',
'سقیفه',
'سکرتر',
'سکسکه',
'سکنات',
'سکونت',
'سکینه',
'سگالش',
'سگرمه',
'سلاخی',
'سلاست',
'سلاسل',
'سلاله',
'سلامت',
'سلایق',
'سلسال',
'سلسله',
'سلطان',
'سلطنت',
'سلندر',
'سلیطه',
'سلیقه',
'سلیله',
'سماجت',
'سماحت',
'سماعی',
'سمانه',
'سماوی',
'سمپات',
'سمسار',
'سنابل',
'سنبله',
'سنجاق',
'سنخیت',
'سندیت',
'سنسور',
'سنکوپ',
'سنگین',
'سنوات',
'سوییچ',
'سوابق',
'سواحل',
'سواری',
'سوانح',
'سوختن',
'سوخته',
'سودجو',
'سوراخ',
'سورچی',
'سورنا',
'سوزان',
'سوسول',
'سوغات',
'سوگلی',
'سوگند',
'سویدا',
'سهمیه',
'سهولت',
'سییات',
'سیاحت',
'سیادت',
'سیاره',
'سیاست',
'سیاسی',
'سیاهه',
'سیاهی',
'سیراب',
'سیرنگ',
'سیستم',
'سیطره',
'سیلاب',
'سیلاب',
'سیلان',
'سیماب',
'سیمان',
'سیمرغ',
'سیمین',
'سینما',
'سیویل',
'شاپور',
'شاخصه',
'شاداب',
'شادان',
'شاگرد',
'شالده',
'شانسی',
'شاهرگ',
'شاهین',
'شایان',
'شایبه',
'شایست',
'شایعه',
'شیامت',
'شبانه',
'شباهت',
'شبکور',
'شبگرد',
'شبگیر',
'شترنگ',
'شجاعت',
'شخصیت',
'شرارت',
'شراره',
'شرافت',
'شراکت',
'شرایط',
'شرطلب',
'شریان',
'شریعت',
'شریکی',
'شستشو',
'شطارت',
'شطرنج',
'شعایر',
'شعبده',
'شعشعه',
'شفاعت',
'شفاهی',
'شقاوت',
'شقایق',
'شقیقه',
'شکایت',
'شکراب',
'شکرین',
'شکستن',
'شکسته',
'شکفته',
'شکنجه',
'شکوفه',
'شکیبا',
'شگفتا',
'شگفتی',
'شلخته',
'شلوار',
'شلوغی',
'شلیطه',
'شماتت',
'شمارش',
'شماره',
'شمایل',
'شمردن',
'شمرده',
'شمشاد',
'شمشیر',
'شمیمه',
'شناخت',
'شناسا',
'شناعت',
'شناور',
'شنگول',
'شنیدن',
'شنیعت',
'شواهد',
'شوایب',
'شوخگن',
'شوربا',
'شورشی',
'شهادت',
'شهامت',
'شهروا',
'شهریه',
'شهوتی',
'شیادی',
'شیپور',
'شیردل',
'شیرده',
'شیرین',
'شیزان',
'شیطان',
'شیطنت',
'شیفته',
'صابری',
'صاعقه',
'صباحت',
'صباغت',
'صباوت',
'صبحدم',
'صبوحی',
'صبوری',
'صحابه',
'صحیفه',
'صدارت',
'صداقت',
'صراحت',
'صراحی',
'صعوبت',
'صلابت',
'صلابه',
'صلحجو',
'صلوات',
'صمدیت',
'صمیمی',
'صناعت',
'صندوق',
'صنوبر',
'صومعه',
'صیانت',
'صیرفی',
'صیقلی',
'ضابطه',
'ضخامت',
'ضربان',
'ضرغام',
'ضرورت',
'ضروری',
'ضلالت',
'ضمانت',
'ضمیمه',
'ضیافت',
'طارمی',
'طاعات',
'طاغوت',
'طاقچه',
'طامات',
'طاهره',
'طایفه',
'طبابت',
'طباخی',
'طباعی',
'طبایع',
'طبرزد',
'طبقات',
'طبیعت',
'طبیعی',
'طراحی',
'طراده',
'طراری',
'طرازی',
'طراوت',
'طرفگی',
'طرفیت',
'طرفین',
'طرلان',
'طریفه',
'طریقت',
'طریقه',
'طغیان',
'طفیلی',
'طلاقت',
'طلایع',
'طلایه',
'طلایی',
'طلیعه',
'طماعی',
'طنازی',
'طنبور',
'طنطنه',
'طواغی',
'طوافی',
'طوایف',
'طوعاً ',
'طوفان',
'طومار',
'طویله',
'طهارت',
'طیاره',
'طیران',
'طیرگی',
'ظاهری',
'ظرافت',
'ظرفیت',
'ظلمات',
'ظواهر',
'عایله',
'عارضه',
'عاریه',
'عاطفه',
'عافیت',
'عاقبت',
'عایدی',
'عبادت',
'عبارت',
'عتیقه',
'عجوزه',
'عدالت',
'عداوت',
'عدلیه',
'عدیده',
'عذوبت',
'عربده',
'عرفان',
'عروسک',
'عروسی',
'عریان',
'عریضه',
'عریکه',
'عزایم',
'عزوبت',
'عزیمت',
'عشیات',
'عشیره',
'عصابه',
'عصاره',
'عصبیت',
'عصفور',
'عصیان',
'عطاری',
'عطارد',
'عطشان',
'عطوفت',
'عفریت',
'عفونت',
'عفیفه',
'عقوبت',
'عقیده',
'علاقه',
'علامت',
'علامه',
'علاوه',
'علوفه',
'عمارت',
'عماره',
'عماری',
'عملگی',
'عمومی',
'عنوان',
'عوارض',
'عوالم',
'عوایق',
'عواید',
'عیادت',
'عیاری',
'عیاشی',
'غایله',
'غاشیه',
'غالیه',
'غایله',
'غثیان',
'غرامت',
'غربال',
'غربتی',
'غربیل',
'غرقاب',
'غرنده',
'غریبه',
'غریزه',
'غریزی',
'غشاوه',
'غضبان',
'غضنفر',
'غفران',
'غلامی',
'غلغله',
'غلمان',
'غلیان',
'غمامه',
'غمبار',
'غمزده',
'غمکده',
'غمگین',
'غمناک',
'غنودن',
'غنوده',
'غنیمت',
'غواصی',
'غیابی',
'غیراز',
'غیرتی',
'فاتحه',
'فاجعه',
'فاحشه',
'فاخته',
'فارسی',
'فاصله',
'فاکهه',
'فالگو',
'فالیز',
'فامیل',
'فانوس',
'فایده',
'فتراک',
'فدایی',
'فراخی',
'فراری',
'فراست',
'فراغت',
'فراهم',
'فربهی',
'فرتوت',
'فرجاد',
'فرجام',
'فردار',
'فردوس',
'فرزند',
'فرسنگ',
'فرضیه',
'فرمان',
'فرمند',
'فرمول',
'فرنود',
'فروتن',
'فرودم',
'فروزش',
'فروکش',
'فرویش',
'فرهنگ',
'فریاد',
'فریبا',
'فریضه',
'فریور',
'فزونی',
'فسیله',
'فشرده',
'فصاحت',
'فضاحت',
'فضیحت',
'فضیلت',
'فعلگی',
'فقاهت',
'فقدان',
'فکاهی',
'فکسنی',
'فلاحت',
'فلاخن',
'فلاکت',
'فلانی',
'فلسفه',
'فوران',
'فوگان',
'فولاد',
'فهرست',
'فیروز',
'فیلتر',
'قابله',
'قاچاق',
'قاطبه',
'قاعده',
'قافله',
'قاموس',
'قانون',
'قایمه',
'قباحت',
'قباله',
'قبراق',
'قبیله',
'قحبگی',
'قدیمی',
'قذرات',
'قرایت',
'قرابت',
'قرابه',
'قراضه',
'قراول',
'قراین',
'قربان',
'قرطاس',
'قرقره',
'قریحه',
'قرینه',
'قساوت',
'قشقرق',
'قشلاق',
'قشنگی',
'قصیده',
'قصیره',
'قضاوت',
'قطیفه',
'قلابی',
'قلمرو',
'قلمزن',
'قلندر',
'قناعت',
'قندیل',
'قوادی',
'قواعد',
'قهقرا',
'قیادت',
'قیافه',
'قیامت',
'قیقاج',
'قیماق',
'قیمتی',
'کابوس',
'کابین',
'کاربر',
'کارتل',
'کارتن',
'کاردک',
'کارکن',
'کارگر',
'کاریز',
'کاستی',
'کاشتن',
'کاشته',
'کاشکی',
'کالبد',
'کالیو',
'کامجو',
'کانال',
'کانون',
'کاواک',
'کاهلی',
'کبایر',
'کبریا',
'کبکبه',
'کبوتر',
'کبودی',
'کبیره',
'کتابت',
'کتمان',
'کتیبه',
'کثافت',
'کجاوه',
'کجروی',
'کدخدا',
'کدورت',
'کدیور',
'کرامت',
'کرانه',
'کراهت',
'کرایه',
'کرباس',
'کردار',
'کریاس',
'کژبین',
'کسادی',
'کسالت',
'کشتار',
'کشتگر',
'کشخان',
'کشکول',
'کشمکش',
'کشنده',
'کشیدن',
'کشیده',
'کفاره',
'کفاشی',
'کفالت',
'کفایت',
'کفران',
'کفگیر',
'کلاسه',
'کلافه',
'کلاله',
'کلانی',
'کلفتی',
'کلیات',
'کلیسا',
'کلیمی',
'کمانک',
'کمانه',
'کمانی',
'کمبود',
'کمرنگ',
'کمیاب',
'کمیته',
'کمینه',
'کناره',
'کنایه',
'کنایی',
'کنترل',
'کندرو',
'کنکاش',
'کنکور',
'کنگره',
'کننده',
'کنیسه',
'کوپال',
'کوتاه',
'کوچکی',
'کودتا',
'کودکی',
'کودنی',
'کوران',
'کوردل',
'کوفتن',
'کوفته',
'کوکبه',
'کولاک',
'کوهان',
'کهانت',
'کهربا',
'کهنگی',
'کهولت',
'کیاست',
'کیایی',
'کیفور',
'کیفیت',
'کیمیا',
'کیوان',
'کیوسک',
'کیهان',
'گاراژ',
'گجسته',
'گداخت',
'گدازش',
'گدایی',
'گذران',
'گذشتن',
'گذشته',
'گرامر',
'گرامی',
'گرانی',
'گرایش',
'گرداب',
'گردان',
'گردنا',
'گردنه',
'گردون',
'گرسنه',
'گرفتن',
'گرفته',
'گریان',
'گریوه',
'گزارش',
'گزاره',
'گزافه',
'گزگزه',
'گزنده',
'گزیدن',
'گزیدن',
'گزیده',
'گزینش',
'گستاخ',
'گسترش',
'گستره',
'گسستن',
'گسسته',
'گشادن',
'گشاده',
'گشادی',
'گشایش',
'گشوده',
'گفتار',
'گفتگو',
'گلابی',
'گلایه',
'گلچین',
'گلرنگ',
'گلریز',
'گلزار',
'گلکار',
'گلگشت',
'گلگون',
'گلوله',
'گمانه',
'گمراه',
'گمشده',
'گمنام',
'گنجور',
'گنجشک',
'گنجفه',
'گنداب',
'گوارا',
'گوارش',
'گواهی',
'گودال',
'گورکن',
'گوریل',
'گوشزد',
'گوهری',
'گویچه',
'گوییا',
'گیلاس',
'گیلکی',
'لااقل',
'لاجرم',
'لازمه',
'لاغری',
'لاغیر',
'لاقید',
'لاهوت',
'لیامت',
'لباده',
'لبالب',
'لبخند',
'لبریز',
'لبلاب',
'لجاجت',
'لجباز',
'لرزان',
'لشکری',
'لطافت',
'لطیفه',
'لعنتی',
'لغایت',
'لغزان',
'لفافه',
'لکاته',
'لمعان',
'لواحق',
'لوازم',
'لوامه',
'لودگی',
'لوستر',
'لوندی',
'لیاقت',
'لیچار',
'لیلاج',
'لیوان',
'مایده',
'مابقی',
'مابین',
'ماتحت',
'ماترک',
'ماتیک',
'ماجرا',
'ماحصل',
'مادام',
'مادگی',
'مادون',
'مازاد',
'ماساژ',
'ماسبق',
'ماسلف',
'ماشرا',
'ماشین',
'مافوق',
'ماقبل',
'مالیه',
'مامان',
'ماندن',
'مانده',
'مانند',
'مانور',
'مانوی',
'ماورا',
'ماورد',
'ماوقع',
'ماهور',
'ماهیت',
'مابون',
'ماجور',
'ماخوذ',
'ماذنه',
'ماذون',
'ماکول',
'مالوف',
'مامور',
'ماموم',
'مامون',
'مانوس',
'مایوس',
'مباحث',
'مباحی',
'مبادا',
'مبادی',
'مبادی',
'مبارز',
'مبارک',
'مباشر',
'مبالغ',
'مبانی',
'مباهی',
'مبایع',
'مباین',
'مبتدا',
'مبتدع',
'مبتدی',
'مبتذل',
'مبتکر',
'مبتلا',
'مبتنی',
'مبتهج',
'مبذول',
'مبرات',
'مبرور',
'مبروص',
'مبرهن',
'مبعوث',
'مبهوت',
'متانت',
'متاثر',
'متاخر',
'متادب',
'متاذی',
'متاسف',
'متاسی',
'متالم',
'متاله',
'متاهل',
'متبحر',
'متبرک',
'متبسم',
'متبوع',
'متتبع',
'متجدد',
'متجسس',
'متجلی',
'متحجر',
'متحذر',
'متحرز',
'متحرک',
'متحصن',
'متحقق',
'متحلی',
'متحمل',
'متحول',
'متحیر',
'متحیز',
'متخصص',
'متخلف',
'متخلق',
'متدرج',
'متدین',
'متذکر',
'متذلل',
'متراژ',
'مترتب',
'مترجم',
'متردد',
'مترسک',
'مترسل',
'مترشح',
'مترصد',
'مترقب',
'مترقی',
'مترنم',
'متروک',
'متزهد',
'متشبث',
'متشتت',
'متشخص',
'متشرع',
'متشکر',
'متشکل',
'متشکی',
'متشنج',
'متصدی',
'متصرف',
'متصلب',
'متصلف',
'متصور',
'متضاد',
'متضرر',
'متضرع',
'متضمن',
'متظلم',
'متعال',
'متعبد',
'متعجب',
'متعدد',
'متعدی',
'متعذر',
'متعرض',
'متعزز',
'متعسر',
'متعسف',
'متعصب',
'متعفن',
'متعلق',
'متعلم',
'متعین',
'متغلب',
'متغیر',
'متفحص',
'متفرد',
'متفرس',
'متفرع',
'متفرق',
'متفطن',
'متفقه',
'متفکر',
'متقال',
'متقبل',
'متقدم',
'متقلب',
'متکبر',
'متکثر',
'متکدی',
'متکسر',
'متکفل',
'متکلف',
'متکلم',
'متلذذ',
'متلون',
'متلهف',
'متمتع',
'متمثل',
'متمدن',
'متمرد',
'متمسک',
'متمکن',
'متملق',
'متمنی',
'متموج',
'متمول',
'متنبه',
'متنجس',
'متنعم',
'متنفذ',
'متنفر',
'متنوع',
'متوجه',
'متوحش',
'متورع',
'متورم',
'متوسط',
'متوسل',
'متوطن',
'متوفا',
'متوفی',
'متوقع',
'متوقف',
'متولد',
'متولی',
'متوهم',
'متهتک',
'متهجد',
'متهور',
'متیقظ',
'متیقن',
'مثابه',
'مثانه',
'مثقال',
'مثلاً ',
'مثنوی',
'مجادل',
'مجاری',
'مجازی',
'مجاعه',
'مجانی',
'مجاور',
'مجاهد',
'مجتمع',
'مجتهد',
'مجذوب',
'مجذور',
'مجذوم',
'مجردی',
'مجروح',
'مجسطی',
'مجسمه',
'مجعول',
'مجلات',
'مجلسی',
'مجملا',
'مجموع',
'مجنون',
'مجهول',
'مچاله',
'محابا',
'محاجه',
'محاذی',
'محارب',
'محارم',
'محاسب',
'محاسن',
'محاضر',
'محافظ',
'محافل',
'محاکم',
'محاله',
'محامد',
'محبوب',
'محبوس',
'محتاج',
'محتاط',
'محتال',
'محترز',
'محترف',
'محترق',
'محترم',
'محتشم',
'محتضر',
'محتکر',
'محتلم',
'محتمل',
'محتوا',
'محتوم',
'محتوی',
'محجبه',
'محجوب',
'محجور',
'محدود',
'محذور',
'محذوف',
'محراب',
'محرقه',
'محروس',
'محروم',
'محزون',
'محسوب',
'محسوس',
'محشور',
'محصنه',
'محصور',
'محصول',
'محظور',
'محظوظ',
'محفظه',
'محفوظ',
'محکمه',
'محکمی',
'محکوم',
'محلات',
'محلوج',
'محلول',
'محمدت',
'محمود',
'محمول',
'محوری',
'محوطه',
'محیلی',
'مخادع',
'مخارج',
'مخازن',
'مخاصم',
'مخاطب',
'مخافت',
'مخالف',
'مختار',
'مختال',
'مخترع',
'مختصر',
'مختصه',
'مختفی',
'مختلس',
'مختلط',
'مختلف',
'مختوم',
'مختون',
'مخدره',
'مخدوش',
'مخدوم',
'مخذول',
'مخروب',
'مخروط',
'مخلوط',
'مخلوع',
'مخلوق',
'مخمصه',
'مخملی',
'مخمور',
'مخیله',
'مداحی',
'مداخل',
'مدارا',
'مدارج',
'مدارس',
'مدارک',
'مدافع',
'مداقه',
'مداوا',
'مداوم',
'مداهن',
'مدایح',
'مداین',
'مدبری',
'مدرسه',
'مدرسی',
'مدفوع',
'مدفون',
'مدلول',
'مدنیت',
'مدهوش',
'مدیحه',
'مدیست',
'مدینه',
'مدیون',
'مذاهب',
'مذبذب',
'مذبوح',
'مذکور',
'مذموم',
'مذهبی',
'مریوس',
'مرایی',
'مراتب',
'مراتع',
'مراثی',
'مراجع',
'مراحل',
'مراحم',
'مرارت',
'مراره',
'مراسم',
'مرافق',
'مراقب',
'مراکز',
'مراهق',
'مربوب',
'مربوط',
'مرتاض',
'مرتبط',
'مرتبه',
'مرتجع',
'مرتجل',
'مرتجی',
'مرتسم',
'مرتشی',
'مرتعش',
'مرتفع',
'مرتکب',
'مرتهن',
'مرثیه',
'مرجوع',
'مرحبا',
'مرحله',
'مرحمت',
'مرحوم',
'مرخصی',
'مرداب',
'مردار',
'مردمی',
'مردنی',
'مردود',
'مرسوم',
'مرصاد',
'مرضات',
'مرطوب',
'مرعوب',
'مرغوا',
'مرغوب',
'مرفوع',
'مرفین',
'مرقوم',
'مرکزی',
'مرکور',
'مرکوز',
'مرموز',
'مرموق',
'مرهون',
'مریضی',
'مزاحم',
'مزارع',
'مزایا',
'مزبله',
'مزبور',
'مزجات',
'مزخرف',
'مزدوج',
'مزدوج',
'مزدور',
'مزرعه',
'مزروع',
'مزعفر',
'مزغان',
'مزمار',
'مزمزه',
'مژگان',
'مسیول',
'مساجد',
'مساحت',
'مساعد',
'مساعی',
'مسافت',
'مسافح',
'مسافر',
'مساکن',
'مسالک',
'مسامح',
'مساوی',
'مسبوق',
'مستبد',
'مستتر',
'مستحب',
'مستحق',
'مستدل',
'مسترد',
'مستعد',
'مستغل',
'مستقر',
'مستقل',
'مستمر',
'مستمع',
'مستند',
'مستور',
'مستوی',
'مسجدی',
'مسحور',
'مسخره',
'مسدود',
'مسرور',
'مسطره',
'مسطور',
'مسعود',
'مسقطی',
'مسکنت',
'مسکوت',
'مسکوک',
'مسکون',
'مسکین',
'مسگری',
'مسلسل',
'مسلوب',
'مسلول',
'مسمار',
'مسموع',
'مسموم',
'مسواک',
'مسوده',
'مسیحی',
'مشیمه',
'مشیوم',
'مشابه',
'مشارق',
'مشاطه',
'مشاعر',
'مشاغل',
'مشاکل',
'مشاور',
'مشاهد',
'مشایخ',
'مشبهه',
'مشتاق',
'مشتبه',
'مشتری',
'مشتعل',
'مشتلق',
'مشتمل',
'مشتوک',
'مشتهر',
'مشحون',
'مشخصه',
'مشربه',
'مشرفه',
'مشرقی',
'مشروب',
'مشروط',
'مشروع',
'مشعبد',
'مشعشع',
'مشعله',
'مشعوف',
'مشغله',
'مشغول',
'مشقات',
'مشکات',
'مشکسا',
'مشکوک',
'مشکین',
'مشمیز',
'مشمول',
'مشورت',
'مشهود',
'مشهور',
'مشیمه',
'مصاحب',
'مصادف',
'مصارف',
'مصالح',
'مصباح',
'مصداق',
'مصدوم',
'مصراع',
'مصروع',
'مصروف',
'مصطبه',
'مصطفی',
'مصطلح',
'مصلحت',
'مصلوب',
'مصنوع',
'مصوری',
'مصیبت',
'مضارع',
'مضاعف',
'مضایق',
'مضبوط',
'مضحکه',
'مضراب',
'مضرات',
'مضروب',
'مضطرب',
'مضمار',
'مضمحل',
'مضمضه',
'مضموم',
'مضمون',
'مضیقه',
'مطابق',
'مطالب',
'مطاوع',
'مطاوی',
'مطبعه',
'مطبوخ',
'مطبوع',
'مطران',
'مطربه',
'مطربی',
'مطرقه',
'مطرود',
'مطلقه',
'مطلوب',
'مطمین',
'مظالم',
'مظاهر',
'مظاهر',
'مظروف',
'مظلمه',
'مظلوم',
'مظنون',
'معابد',
'معابر',
'معادل',
'معادن',
'معارج',
'معارض',
'معارف',
'معاشر',
'معاصر',
'معاصی',
'معاضد',
'معافی',
'معاقب',
'معالج',
'معالم',
'معاند',
'معانی',
'معاون',
'معایب',
'معبود',
'معتاد',
'معتبر',
'معتدل',
'معتذر',
'معترض',
'معترف',
'معتزل',
'معتقد',
'معتکف',
'معتمد',
'معجزه',
'معجون',
'معدلت',
'معدود',
'معدوم',
'معذرت',
'معذور',
'معراج',
'معرفت',
'معرفی',
'معرکه',
'معروض',
'معروف',
'معزول',
'معشوق',
'معصوم',
'معصیت',
'معطلی',
'معطوف',
'معقول',
'معکوس',
'معلاق',
'معلمی',
'معلول',
'معلوم',
'معمار',
'معمور',
'معمول',
'معنبر',
'معنوی',
'معوقه',
'معونت',
'معیار',
'معیشت',
'معیوب',
'مغاره',
'مغازه',
'مغازی',
'مغایر',
'مغبون',
'مغتنم',
'مغرور',
'مغشوش',
'مغضوب',
'مغفرت',
'مغفور',
'مغلطه',
'مغلوب',
'مغلوط',
'مغلول',
'مغموم',
'مغنیه',
'مفاخر',
'مفاسد',
'مفاصل',
'مفتاح',
'مفتخر',
'مفترض',
'مفترق',
'مفتری',
'مفتضح',
'مفتقر',
'مفتکی',
'مفتوح',
'مفتول',
'مفتون',
'مفروز',
'مفروش',
'مفروض',
'مفروق',
'مفسده',
'مفعول',
'مفقود',
'مفلسی',
'مفلوج',
'مفلوک',
'مفنگی',
'مفهوم',
'مقابر',
'مقابل',
'مقاتل',
'مقارن',
'مقاصد',
'مقاطع',
'مقاله',
'مقامر',
'مقامه',
'مقاوم',
'مقبره',
'مقبول',
'مقتبس',
'مقتدا',
'مقتدر',
'مقترن',
'مقتصد',
'مقتضا',
'مقتضی',
'مقتول',
'مقدار',
'مقدمه',
'مقدور',
'مقراض',
'مقرری',
'مقرعه',
'مقرنس',
'مقروض',
'مقرون',
'مقسوم',
'مقصود',
'مقصور',
'مقطوع',
'مقلاد',
'مقلوب',
'مقنعه',
'مقننه',
'مقوله',
'مقهور',
'مقیاس',
'مکاتب',
'مکارم',
'مکاره',
'مکاری',
'مکاری',
'مکاسب',
'مکانت',
'مکاید',
'مکتبی',
'مکتسب',
'مکتشف',
'مکتوب',
'مکتوم',
'مکثار',
'مکرمت',
'مکروه',
'مکشوف',
'مکنون',
'مکیدن',
'مکینه',
'ملاحت',
'ملازم',
'ملاست',
'ملافه',
'ملاقه',
'ملالت',
'ملامت',
'ملاهی',
'ملایم',
'ملایی',
'ملبوس',
'ملتبس',
'ملتجا',
'ملتجی',
'ملتزم',
'ملتفت',
'ملتمس',
'ملتهب',
'ملحفه',
'ملحوظ',
'ملعبه',
'ملعنت',
'ملعون',
'ملفوظ',
'ملکوت',
'ملکوک',
'ملکول',
'ملکیت',
'ملموس',
'ملوان',
'ملودی',
'ملهوف',
'ممالک',
'ممتاز',
'ممتحن',
'ممتلی',
'ممتنع',
'ممدوح',
'ممدود',
'ممزوج',
'ممسکی',
'مملکت',
'مملوک',
'ممنوع',
'ممنون',
'ممهور',
'ممیزی',
'منابر',
'منابع',
'منادی',
'مناره',
'منازع',
'منازل',
'مناسب',
'مناسک',
'مناصب',
'مناطق',
'مناظر',
'مناعت',
'منافذ',
'منافع',
'منافق',
'منافی',
'مناقب',
'مناهج',
'مناهی',
'منبری',
'منبسط',
'منبعث',
'منتخب',
'منتزع',
'منتسب',
'منتشا',
'منتشر',
'منتصر',
'منتظر',
'منتظم',
'منتفع',
'منتفی',
'منتقد',
'منتقل',
'منتقم',
'منتها',
'منتهی',
'منثور',
'منجلی',
'منجمد',
'منحرف',
'منحصر',
'منحنی',
'منحوس',
'منحول',
'مندرج',
'مندرس',
'مندوب',
'مندیل',
'منزجر',
'منزلت',
'منزوی',
'منسجم',
'منسوب',
'منسوج',
'منسوخ',
'منشات',
'منشعب',
'منشور',
'منصرف',
'منصرم',
'منصفت',
'منصوب',
'منصور',
'منضبط',
'منطبق',
'منطفی',
'منطقه',
'منطقی',
'منطوق',
'منطوی',
'منظره',
'منظور',
'منظوم',
'منعطف',
'منعقد',
'منعکس',
'منفجر',
'منفصل',
'منفعت',
'منفعل',
'منفور',
'منقاد',
'منقار',
'منقاش',
'منقبت',
'منقبض',
'منقرض',
'منقسم',
'منقصت',
'منقضی',
'منقطع',
'منقلب',
'منقوش',
'منقوط',
'منقول',
'منکسر',
'منکوب',
'منکوس',
'منوال',
'منهدم',
'منهزم',
'مواجب',
'مواجه',
'موارد',
'موازی',
'مواشی',
'مواضع',
'مواطن',
'مواظب',
'مواعظ',
'موافق',
'مواقف',
'موالی',
'موانع',
'مواهب',
'موبور',
'موتور',
'موثوق',
'موجود',
'موچین',
'موروث',
'موزون',
'موزیک',
'موسمی',
'موسوم',
'موسوی',
'موسیر',
'موصوف',
'موصول',
'موضوع',
'موعدی',
'موعظه',
'موعود',
'موفور',
'موقتی',
'موقوف',
'موکول',
'مولود',
'مومیا',
'موهبت',
'موهوب',
'موهوم',
'مویان',
'مهابت',
'مهاجر',
'مهارت',
'مهالک',
'مهتاب',
'مهتری',
'مهجور',
'مهدوم',
'مهریه',
'مهزوم',
'مهشید',
'مهلکه',
'مهمات',
'مهمان',
'مهملی',
'مهموز',
'مهموم',
'مهندس',
'مهیمن',
'میانه',
'میترا',
'میثاق',
'میخوش',
'میدان',
'میراب',
'میراث',
'میزان',
'میسره',
'میسور',
'میشوم',
'میعاد',
'میعان',
'میقات',
'میکده',
'میکرب',
'میگون',
'میلاد',
'میمنت',
'میمنه',
'میمون',
'مینوت',
'موالف',
'موتلف',
'موتمر',
'موتمن',
'موخره',
'موسسه',
'ناامن',
'نااهل',
'ناباب',
'نابجا',
'نابغه',
'نابلد',
'نابود',
'ناپاک',
'ناجنس',
'ناجور',
'ناچار',
'ناچیز',
'ناحیت',
'ناحیه',
'ناخدا',
'ناخرم',
'ناخلف',
'ناخوش',
'نادار',
'نادان',
'نادره',
'نارسا',
'ناروا',
'نازان',
'نازکی',
'ناساز',
'ناسره',
'ناسزا',
'ناسور',
'ناشاد',
'ناشتا',
'ناشکر',
'ناصاف',
'ناصیه',
'ناطقه',
'ناطور',
'ناقلا',
'ناقوس',
'ناکام',
'ناکسی',
'ناگاه',
'نالان',
'نامجو',
'نامرد',
'نامزد',
'نامور',
'ناموس',
'نامیه',
'نانوا',
'ناورد',
'ناهار',
'ناهید',
'نایاب',
'نایره',
'نبراس',
'نبرده',
'نبشته',
'نبهره',
'نبیره',
'نپخته',
'نتیجه',
'نجابت',
'نجاست',
'نجومی',
'نحوست',
'نخاله',
'نخجیر',
'نداری',
'ندافی',
'ندامت',
'نداوت',
'ندیمه',
'نرگسه',
'نرمال',
'نرموک',
'نرینه',
'نزاکت',
'نزدیک',
'نژاده',
'نژندی',
'نساجی',
'نستوه',
'نسناس',
'نسوان',
'نسیان',
'نشانه',
'نشانی',
'نشدنی',
'نشریه',
'نشستن',
'نشیمن',
'نصارا',
'نصیبه',
'نصیحت',
'نضارت',
'نظارت',
'نظاره',
'نظافت',
'نظامی',
'نظریه',
'نظمیه',
'نعلین',
'نعومت',
'نفاست',
'نفتکش',
'نفرین',
'نقابت',
'نقادی',
'نقاره',
'نقاشی',
'نقاضت',
'نقصان',
'نقمات',
'نقیصه',
'نکورو',
'نکوهش',
'نگارش',
'نگاره',
'نگران',
'نمامی',
'نمایش',
'نمدار',
'نمسار',
'نمکین',
'نمگین',
'نمناک',
'نمودن',
'نمونه',
'ننگین',
'نواور',
'نواحی',
'نواخت',
'نواده',
'نوازش',
'نواگر',
'نواله',
'نوایب',
'نوساز',
'نوسان',
'نوشتن',
'نوشته',
'نوشین',
'نوکار',
'نوگرا',
'نومید',
'نویسه',
'نهادن',
'نهالی',
'نهامی',
'نهانی',
'نهایت',
'نهایی',
'نهفتن',
'نهفته',
'نهمار',
'نیابت',
'نیایش',
'نیران',
'نیرنج',
'نیرنگ',
'نیزار',
'نیستی',
'نیشتر',
'نیکخو',
'نیکخو',
'نیوشا',
'واتگر',
'واجبی',
'وادار',
'وارسی',
'وارون',
'وازده',
'واسطه',
'واقعه',
'واقعی',
'واکنش',
'واگشت',
'واگیر',
'والده',
'واهمه',
'وثاقت',
'وثیقه',
'وجاهت',
'وجدان',
'وخشور',
'ودیعه',
'وراجی',
'وردست',
'ورودی',
'وزارت',
'وزیدن',
'وساده',
'وساطت',
'وسایط',
'وسایل',
'وسواس',
'وسوسه',
'وسیله',
'وصایت',
'وضعیت',
'وطواط',
'وظیفه',
'وقاحت',
'وقایت',
'وقایع',
'وکالت',
'وگرنه',
'ولادت',
'ولایت',
'ولخرج',
'ولگرد',
'ولوله',
'ولیمه',
'ویران',
'ویروس',
'ویزیت',
'ویژگی',
'ویلان',
'هارون',
'هاضمه',
'هامون',
'هاویه',
'هجران',
'هجویه',
'هدایت',
'هذیان',
'هرچند',
'هردری',
'هرروز',
'هرزگی',
'هرماس',
'هزیمت',
'هزینه',
'هشدار',
'هشیار',
'هلاکت',
'هلاهل',
'هلهله',
'هماره',
'همانا',
'هماور',
'همباز',
'همدست',
'همدلی',
'همدمی',
'همراز',
'همراه',
'همرنگ',
'همریش',
'همزاد',
'همساز',
'همسان',
'همکار',
'همگان',
'همگرا',
'همگون',
'هموار',
'هموژن',
'همهمه',
'همیان',
'همیشه',
'هنجار',
'هنرور',
'هنگام',
'هنگفت',
'هوایی',
'هویدا',
'هیاهو',
'هیجان',
'هیربد',
'هیمنه',
'هیولا',
'هیهات',
'یاخته',
'یاسمن',
'یافتن',
'یاوری',
'یبوست',
'یحتمل',
// City Names
'خلخال',
'سرعین',
'انارک',
'تودشک',
'تیران',
'داران',
'دامنه',
'زواره',
'سمیرم',
'شهرضا',
'کاشان',
'کمشچه',
'گرگاب',
'گلدشت',
'گلشهر',
'مشکات',
'نایین',
'نیاسر',
'ورزنه',
'وزوان',
'آسارا',
'ایلام',
'ایوان',
'توحید',
'دلگشا',
'لومار',
'مهران',
'میمه ',
'آقکند',
'تبریز',
'خامنه',
'خراجو',
'خواجه',
'شبستر',
'کلیبر',
'گوگان',
'لیلان',
'مراغه',
'ملکان',
'ممقان',
'میانه',
'یامچی',
'باروق',
'بوکان',
'پلدشت',
'سردشت',
'سلماس',
'قوشچی',
'نالوس',
'نوشین',
'آبپخش',
'آبدان',
'بوشهر',
'چغادک',
'دالکی',
'دلوار',
'سیراف',
'آبسرد',
'آبعلی',
'بومهن',
'پردیس',
'پیشوا',
'تجریش',
'تهران',
'رودهن',
'کیلان',
'ملارد',
'آلونی',
'بروجن',
'چلگرد',
'سامان',
'ناغان',
'اسدیه',
'اسفدن',
'فردوس',
'انابد',
'باخرز',
'بیدخت',
'جغتای',
'رضویه',
'روداب',
'سلامی',
'سنگان',
'ششتمد',
'شهرزو',
'طرقبه',
'قوچان',
'کاریز',
'کاشمر',
'یونسی',
'جاجرم',
'شوقان',
'فاروج',
'لوجلی',
'الوان',
'اهواز',
'بستان',
'چمران',
'دزفول',
'سالند',
'سماله',
'شاوور',
'شرافت',
'شوشتر',
'شیبان',
'صیدون',
'گتوند',
'گوریه',
'هفتگل',
'هویزه',
'آب بر',
'چورزق',
'زنجان',
'قیدار',
'گرماب',
'بسطام',
'دیباج',
'سمنان',
'میامی',
'ادیمی',
'اسپکه',
'بزمان',
'بمپور',
'بنجار',
'پیشین',
'زابلی',
'سرباز',
'سوران',
'کنارک',
'محمدی',
'هیدوج',
'اقلید',
'آباده',
'حسامی',
'خرامه',
'داراب',
'دژکرد',
'رونیز',
'زرقان',
'سورمق',
'سیدان',
'شیراز',
'فدامی',
'لامرد',
'لپویی',
'لطیفی',
'مشکان',
'مصیری',
'میمند',
'نوجین',
'نودان',
'وراوی',
'ارداق',
'الوند',
'آبگرم',
'قزوین',
'کوهین',
'قنوات',
'بیجار',
'چناره',
'زرینه',
'سنندج',
'شویشه',
'باغین',
'بروات',
'پاریز',
'جوپار',
'جیرفت',
'چترود',
'خانوک',
'شهداد',
'کرمان',
'کهنوج',
'گلباف',
'گلزار',
'ماهان',
'مردهک',
'ازگله',
'سرمست',
'سومار',
'نودشه',
'نوسود',
'هرسین',
'چیتاب',
'دهدشت',
'یاسوج',
'ترکمن',
'کلاله',
'گرگان',
'اسالم',
'رودسر',
'شلمان',
'کومله',
'لوشان',
'لیسار',
'ماسال',
'مرجقل',
'منجیل',
'هشتپر',
'الشتر',
'چقابل',
'دورود',
'آلاشت',
'بهشهر',
'چالوس',
'رامسر',
'رویان',
'زیرآب',
'شیرود',
'کیاسر',
'نوشهر',
'پرندک',
'خنداب',
'زاویه',
'ساروق',
'سنجان',
'شازند',
'محلات',
'نیمور',
'بیکاه',
'رویدر',
'سندرک',
'سیریک',
'میناب',
'برزول',
'جوکار',
'زنگنه',
'سرکان',
'فرسفج',
'ملایر',
'همدان',
'اشکذر',
'دیهوک',
'مروست',
'مهریز',
'ندوشن'
]