import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="راهنمای بازی" isOpen={isOpen} handleClose={handleClose}>
      <p dir="rtl" className="text-sm text-gray-500 dark:text-gray-300">
        به واژه ساز (وردل فارسی) خوش اومدی. 
      </p>
      <br></br>
<hr></hr>
<br></br>
      <p dir="rtl" className="text-sm text-gray-500 dark:text-gray-300">
        6 بار فرصت داری واژه رمز رو حدس بزنی. 
        بعد از هر حدس، رنگ خانه ها نشان می دهند چقدر به هدف نزدیک شدی.
        اگه ناامید شدی با کلیک روی دکمه "واژه جدید" واژه رمز رو عوض کن!
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ه"  />
        <Cell value="د" />
        <Cell value="ن" />
        <Cell value="ر" />
        <Cell value="پ" status="correct"/>
      </div>
      <p dir="rtl" className="text-sm text-gray-500 dark:text-gray-300">
        حرف <strong>پ</strong> در واژه مورد نظر موجود است و در جای درستش قرار دارد.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ر" />
        <Cell value="و" />
        <Cell value="ن" status="present" />
        <Cell value="ا" />
        <Cell value="ج" />
      </div>
      <p dir="rtl" className="text-sm text-gray-500 dark:text-gray-300">
        حرف ن در واژه مورد نظر موجود است اما در جای درستش نیست.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="ی" />
        <Cell value="و" />
        <Cell value="ن" />
        <Cell value="ث" status="absent" />
        <Cell value="م" />
      </div>
      <p dir="rtl" className="text-sm text-gray-500 dark:text-gray-300">
        حرف ث در واژه مورد نظر وجود ندارد.
      </p>
      <br></br>

      <p dir="rtl" className="text-sm text-gray-500 dark:text-gray-300">
        واژه ساز همیشه رایگان خواهد ماند.
      </p>
    </BaseModal>
  )
}