export const WORDS_CITIES = [
'خلخال',
'سرعین',
'انارک',
'تودشک',
'تیران',
'داران',
'دامنه',
'زواره',
'سمیرم',
'شهرضا',
'کاشان',
'کمشچه',
'گرگاب',
'گلدشت',
'گلشهر',
'مشکات',
'نایین',
'نیاسر',
'ورزنه',
'وزوان',
'آسارا',
'ایلام',
'ایوان',
'توحید',
'دلگشا',
'لومار',
'مهران',
'میمه ',
'آقکند',
'تبریز',
'خامنه',
'خراجو',
'خواجه',
'شبستر',
'کلیبر',
'گوگان',
'لیلان',
'مراغه',
'ملکان',
'ممقان',
'میانه',
'یامچی',
'باروق',
'بوکان',
'پلدشت',
'سردشت',
'سلماس',
'قوشچی',
'نالوس',
'نوشین',
'آبپخش',
'آبدان',
'بوشهر',
'چغادک',
'دالکی',
'دلوار',
'سیراف',
'آبسرد',
'آبعلی',
'بومهن',
'پردیس',
'پیشوا',
'تجریش',
'تهران',
'رودهن',
'کیلان',
'ملارد',
'آلونی',
'بروجن',
'چلگرد',
'سامان',
'ناغان',
'اسدیه',
'اسفدن',
'فردوس',
'انابد',
'باخرز',
'بیدخت',
'جغتای',
'رضویه',
'روداب',
'سلامی',
'سنگان',
'ششتمد',
'شهرزو',
'طرقبه',
'قوچان',
'کاریز',
'کاشمر',
'یونسی',
'جاجرم',
'شوقان',
'فاروج',
'لوجلی',
'الوان',
'اهواز',
'بستان',
'چمران',
'دزفول',
'سالند',
'سماله',
'شاوور',
'شرافت',
'شوشتر',
'شیبان',
'صیدون',
'گتوند',
'گوریه',
'هفتگل',
'هویزه',
'آب بر',
'چورزق',
'زنجان',
'قیدار',
'گرماب',
'بسطام',
'دیباج',
'سمنان',
'میامی',
'ادیمی',
'اسپکه',
'بزمان',
'بمپور',
'بنجار',
'پیشین',
'زابلی',
'سرباز',
'سوران',
'کنارک',
'محمدی',
'هیدوج',
'اقلید',
'آباده',
'حسامی',
'خرامه',
'داراب',
'دژکرد',
'رونیز',
'زرقان',
'سورمق',
'سیدان',
'شیراز',
'فدامی',
'لامرد',
'لپویی',
'لطیفی',
'مشکان',
'مصیری',
'میمند',
'نوجین',
'نودان',
'وراوی',
'ارداق',
'الوند',
'آبگرم',
'قزوین',
'کوهین',
'قنوات',
'بیجار',
'چناره',
'زرینه',
'سنندج',
'شویشه',
'باغین',
'بروات',
'پاریز',
'جوپار',
'جیرفت',
'چترود',
'خانوک',
'شهداد',
'کرمان',
'کهنوج',
'گلباف',
'گلزار',
'ماهان',
'مردهک',
'ازگله',
'سرمست',
'سومار',
'نودشه',
'نوسود',
'هرسین',
'چیتاب',
'دهدشت',
'یاسوج',
'ترکمن',
'کلاله',
'گرگان',
'اسالم',
'رودسر',
'شلمان',
'کومله',
'لوشان',
'لیسار',
'ماسال',
'مرجقل',
'منجیل',
'هشتپر',
'الشتر',
'چقابل',
'دورود',
'آلاشت',
'بهشهر',
'چالوس',
'رامسر',
'رویان',
'زیرآب',
'شیرود',
'کیاسر',
'نوشهر',
'پرندک',
'خنداب',
'زاویه',
'ساروق',
'سنجان',
'شازند',
'محلات',
'نیمور',
'بیکاه',
'رویدر',
'سندرک',
'سیریک',
'میناب',
'برزول',
'جوکار',
'زنگنه',
'سرکان',
'فرسفج',
'ملایر',
'همدان',
'اشکذر',
'دیهوک',
'مروست',
'مهریز',
'ندوشن'
]