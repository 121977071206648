import { BaseModal2 } from './BaseModal2'

type Props = {
  isOpen: boolean
  lastWord: string
  handleClose: () => void
}

export const WordModal = ({ isOpen, lastWord, handleClose }: Props) => {
  return (
    <BaseModal2
      width="50vw"
      title={`واژه رمز:  ${lastWord}`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div
        id="meaning"
        style={{
          color: 'white',
          direction: 'rtl',
        }}
      >
        <p>معنی واژه ها به زودی!</p>
      </div>
    </BaseModal2>
  )
}
